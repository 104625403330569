const splitText = (target) => {
  const results = window.Splitting({
    target,
    by: 'lines',
    whitespace: true,
  });

  const lines = results[0].lines.map((line, index) => {
    const words = [];

    line.forEach((word) => {
      words.push(word.textContent);
    });

    return `<div class="line" style="--line-index: ${index}"><div class="line-inner">${words.join(' ')}</div></div>`;
  });

  target.textContent = '';
  target.insertAdjacentHTML('beforeend', lines.join(' '));
};

const initTextSplitAnimation = () => {
  const textElements = document.querySelectorAll('[data-lines-animation]');

  if (!textElements.length) {
    return;
  }

  textElements.forEach(splitText);
};

export {initTextSplitAnimation, splitText};
