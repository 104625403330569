class PageHeader {
  constructor() {
    this._header = document.querySelector('[data-header]');
    this._headerToggler = document.querySelector('[data-header-toggler]');
    this._headerOverlay = document.querySelector('[data-header-overlay]');
    this._onHeaderTogglerClick = this._onHeaderTogglerClick.bind(this);
    this._onDocumentKeydown = this._onDocumentKeydown.bind(this);
    this._onOverlayClick = this._onOverlayClick.bind(this);
    this._navLinks = document.querySelectorAll('[data-nav-link]');
    this._breakpoint = window.matchMedia('(max-width:767px)');
    this._onWindowScroll = this._onWindowScroll.bind(this);
  }

  _openHeader() {
    this._header.classList.add('is-open');
    this._headerToggler.setAttribute('aria-pressed', 'true');
    document.addEventListener('keydown', this._onDocumentKeydown);
    window.scrollLock.disableScrolling();

    this._headerOverlay.addEventListener('click', this._onOverlayClick);

    if (window.location.pathname.slice(1) === 'index.html') {
      const hash = window.location.hash.replace('#', '');
      const isAbout = hash === 'about';

      if (isAbout) {
        this._navLinks.forEach((link) => {
          if (link.dataset.navLink === 'about') {
            link.classList.add('no-pointer');
          }
        });
      }
    }
  }

  _closeHeader() {
    this._header.classList.remove('is-open');
    this._headerToggler.setAttribute('aria-pressed', 'false');
    document.removeEventListener('keydown', this._onDocumentKeydown);
    window.scrollLock.enableScrolling();

    this._headerOverlay.removeEventListener('click', this._onOverlayClick);
  }

  _onOverlayClick() {
    this._closeHeader();
  }

  _onHeaderTogglerClick(evt) {
    evt.preventDefault();
    if (this._header.classList.contains('is-open')) {
      this._closeHeader();
      return;
    }
    this._openHeader();
  }

  _onWindowScroll() {
    if (window.pageYOffset > 5) {
      this._header.classList.add('is-fixed');
    } else {
      this._header.classList.remove('is-fixed');
    }
  }

  _onDocumentKeydown(evt) {
    evt.preventDefault();
    if (evt.key === 'Escape') {
      this._closeHeader();
    }
  }

  init() {
    if (!this._header) {
      return;
    }
    this._headerToggler.addEventListener('click', this._onHeaderTogglerClick);
    window.addEventListener('scroll', this._onWindowScroll);
    if (window.pageYOffset > 5) {
      this._header.classList.add('is-fixed');
    }
  }
}

const initPageHeader = () => {
  const pageHeader = new PageHeader();
  pageHeader.init();

  window.pageHeader = pageHeader;
};

export {initPageHeader};
