import {renderVideoMockModal} from '../timeline-controller';

const filmIntroImage = ({path, format, alt}) => {
  return (`
    <picture>
      <source type="image/webp" srcset="${path}.webp, ${path}@2x.webp 2x">
      <img src="${path}.${format}" srcset="${path}@2x.${format} 2x" width="247" height="328" alt="${alt}">
    </picture>
  `);
};

const filmIntroText = ({text}) => {
  return (`
    <p class="doc-intro__text">${text}</p>
  `);
};

const filmItem = ({name, previewPicture, linkVideo, previewText, detailText, ufVideoTitle, ufDesc}, i) => {
  return (`
    <li class="doc-content__item" data-animate ${i === 0 ? 'data-animate-height="100"' : ''}>
      <div class="doc-content__item-title-wrp">
        <div class="doc-content__item-title" data-parallax="">
          <div class="doc-content__item-title-text"><span class="doc-content__item-title-word">${name}</span></div>
          ${i % 2 === 0 ? `<div class="doc-content__item-title-figure doc-content__item-title-figure--1" data-parallax-item="" data-speed="2">
            <svg class="doc-content__item-title-icon doc-content__item-title-icon--1" width="74" height="73" aria-hidden="true" style="--figure-index: 0;">
              <use xlink:href="img/sprite_auto.svg#doc-year-1-bg-1"></use>
            </svg>
            <svg class="doc-content__item-title-icon doc-content__item-title-icon--2" width="37" height="37" aria-hidden="true" style="--figure-index: 1;">
              <use xlink:href="img/sprite_auto.svg#doc-year-1-bg-2"></use>
            </svg>
            <svg class="doc-content__item-title-icon doc-content__item-title-icon--3" width="88" height="44" aria-hidden="true" style="--figure-index: 2;">
              <use xlink:href="img/sprite_auto.svg#doc-year-1-bg-3"></use>
            </svg>
          </div>` : `<div class="doc-content__item-title-figure doc-content__item-title-figure--2" data-parallax-item="" data-speed="2">
            <svg class="doc-content__item-title-icon doc-content__item-title-icon--1" width="162" height="193" aria-hidden="true" style="--figure-index: 0;">
              <use xlink:href="img/sprite_auto.svg#doc-year-2-bg-1"></use>
            </svg>
            <svg class="doc-content__item-title-icon doc-content__item-title-icon--2" width="43" height="43" aria-hidden="true" style="--figure-index: 1;">
              <use xlink:href="img/sprite_auto.svg#doc-year-2-bg-2"></use>
            </svg>
          </div>`}

        </div>
      </div>
      <div class="doc-content__item-video-wrp"><a class="doc-content__item-video ${linkVideo === '' ? '' : 'is-active'}" ${ufVideoTitle === '' ? '' : 'is-active'}" data-video-title="${ufVideoTitle}" href="${linkVideo === '' ? '#' : linkVideo}" ${linkVideo === '' ? 'data-open-modal="video-mock"' : `data-open-modal="video" data-modal-type="video" data-lazy-onload="${linkVideo}"`}>
          <picture>
            <source type="image/webp" srcset="${previewPicture.path}.webp, ${previewPicture.path}@2x.webp 2x"><img src="${previewPicture.path}.${previewPicture.format}" srcset="${previewPicture.path}@2x.${previewPicture.format} 2x" width="387" height="223" alt="${previewPicture.alt}">
          </picture></a></div>
      <div class="doc-content__item-text-wrp">
        <h3 class="doc-content__subtitle fit-15">${previewText}</h3>
        <p class="doc-content__item-text fit-15 d-200">${detailText}</p>
      </div>
    </li>
  `);
};

// <div class="doc-content__item-video-wrp"><a class="doc-content__item-video" href="#" data-open-modal="video" data-lazy-onload="${videoLink}">

const btnItem = ({name, description}) => {
  return (`
    <a class="btn btn--play doc-intro__btn" href="#" data-modal-type="video" data-open-modal="video" data-lazy-onload="${description}"><span class="btn__icon"><span class="btn__icon-wrp">
      <svg width="12" height="17" aria-hidden="true">
        <use xlink:href="img/sprite_auto.svg#icon-play"></use>
      </svg></span></span><span class="btn__title">${name}</span>
    </a>
  `);
};

const renderFilmsModal = ({target}) => {
  if (target.closest('[data-open-modal="video-mock"]')) {
    let desc = target.closest('[data-open-modal="video-mock"]').dataset.videoDesc;
    let title = target.closest('[data-open-modal="video-mock"]').dataset.videoTitle;
    if (title && title !== 'undefined') {
      renderVideoMockModal(desc, title);
    }
  }
};

const filmsModalsHandler = () => {
  const videoBtns = document.querySelectorAll('[data-open-modal="video-mock"]');

  if (videoBtns.length) {
    videoBtns.forEach((btn) => btn.addEventListener('click', renderFilmsModal));
  }
};

// return (`
//     <a class="btn btn--play doc-intro__btn" href="#" data-open-modal="video" data-lazy-onload="${description}"><span class="btn__icon"><span class="btn__icon-wrp">
//       <svg width="12" height="17" aria-hidden="true">
//         <use xlink:href="img/sprite_auto.svg#icon-play"></use>
//       </svg></span></span><span class="btn__title">${name}</span>
//     </a>
//   `);


export {filmIntroImage, filmIntroText, filmItem, btnItem, filmsModalsHandler};
