import {showIntroText, showBook} from './intro-animation';
import {openWithVideo, playOnlyVideo} from './play-video';
const wrapper = document.querySelector('.wrapper');
const nextBtns = document.querySelectorAll('.intro__launch-btn');
// const introBtn = document.querySelector('[data-sequence-start]');
const seqWrapper = document.querySelector('[data-scroll-parent]');
const openBook = document.querySelector('.open-book');
const intro = document.querySelector('.intro');
const introBg1 = document.querySelector('.intro__sequence-bg--1');
const introBg2 = document.querySelector('.intro__sequence-bg--2');
const breakpointSm = window.matchMedia('(max-width:767px)');
const breakpointMd = window.matchMedia('(max-width:1024px)');
const FPS = 40;
let activeFrame;
let framesArr = [];
let isLoaded = false;

const launchStartSequence = () => {
  const canvas = document.querySelector('[data-sequence-canvas]');
  const context = canvas.getContext('2d');
  const hash = window.location.hash.replace('#', '');
  const isAbout = hash === 'about';

  if (isAbout) {
    return;
  }

  let activeFrames;

  framesArr.forEach((frame, i) => {
    if ('intro' in frame) {
      activeFrames = framesArr[i];
      return;
    }
  });

  const launch = () => {
    if (isLoaded) {
      framesArr.forEach((frame, i) => {
        if ('intro' in frame) {
          activeFrames = framesArr[i];
          return;
        }
      });

      if (!breakpointSm.matches) {
        launchSequence(activeFrames, 0, 171, context, canvas, () => {
          nextBtns.forEach((btn) => {
            btn.classList.add('is-show');
          });
          wrapper.classList.remove('no-pointer');
          showIntroText();
        });
      } else {
        launchSequence(activeFrames, 0, 91, context, canvas, () => {
          wrapper.classList.remove('no-pointer');
          showIntroText();
        });
      }
      return;
    }
    requestAnimationFrame(launch);
  };

  launch();
};

const preloadImages = (frames, frameCount, sequencePath, imageFormat, framesLoaded, onComplete = () => { }) => new Promise((resolve) => {
  for (let i = 0; i <= frameCount; i++) {
    frames[i] = new Image();
    frames[i].src =
      `img/content/${sequencePath}/${i.toString().padStart(4, '0')}.${imageFormat}`;
    frames[i].onload = function () {
      framesLoaded++;
      if (framesLoaded >= frameCount - 1) {
        resolve();
      }
    };
  }
}).then(onComplete);

const updateImage = (index, frames, context, canvas, isContain) => {
  const framesElements = !Array.isArray(frames) ? Object.values(frames)[0] : frames;
  const activeIndex = index < 0 ? 1 : index;

  if (framesElements[activeIndex]) {
    const imgWidth = framesElements[activeIndex].width;
    const imgHeight = framesElements[activeIndex].height;

    const scale = getImageScale(canvas, framesElements[activeIndex], isContain);

    const offsetY = 0;
    const offsetX = canvas.width - imgWidth * scale;

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(framesElements[activeIndex], offsetX / 2, offsetY, imgWidth * scale, imgHeight * scale);
  }
};

const launchSequence = (frames, startFrameIndex, finalFrameIndex, context, canvas, onComplete = () => { }) => new Promise((resolve) => {
  const isReverse = finalFrameIndex - startFrameIndex < 0;
  let frame = startFrameIndex;

  const initSequence = () => {
    if (isLoaded) {
      if (finalFrameIndex - startFrameIndex > 0) {
        frame += 1;
      } else {
        frame -= 1;
      }

      if (!isReverse) {
        if (frame < finalFrameIndex) {
          updateImage(frame, frames, context, canvas);
        } else {
          resolve();
          return;
        }
      } else {
        if (frame > finalFrameIndex) {
          updateImage(frame, frames, context, canvas);
        } else {
          resolve();
          return;
        }
      }
    }

    const framesElements = !Array.isArray(frames) ? Object.values(frames)[0] : frames;
    activeFrame = framesElements[frame];

    setTimeout(() => {
      requestAnimationFrame(initSequence);
    }, 1000 / FPS);
  };

  initSequence();
}).then(onComplete);

const getImageScale = (canvas, activeFrameEl, isContain) => {
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;
  let imgWidth = activeFrameEl.width;
  let imgHeight = activeFrameEl.height;
  imgWidth = imgWidth === 0 ? 1 : imgWidth;
  const scaleX = canvasWidth / imgWidth;
  const scaleY = canvasHeight / imgHeight;
  let scale;
  if (isContain) {
    scale = scaleX > scaleY ? scaleY : scaleX;
  } else {
    scale = scaleX;
  }

  return scale;
};

const setSequenceAction = (btn) => {
  const btnId = btn.dataset.btnId;
  const main = btn.closest('main');
  const canvasParent = main.querySelector(`[data-sequence-id=${btnId}]`);
  const canvas = canvasParent.querySelector('[data-sequence-canvas]');
  const context = canvas.getContext('2d');

  if (breakpointSm.matches) {
    context.mozImageSmoothingEnabled = false;
    context.webkitImageSmoothingEnabled = false;
    context.msImageSmoothingEnabled = false;
    context.imageSmoothingEnabled = false;
  }

  btn.addEventListener('click', () => {
    const firstFrame = breakpointSm.matches ? +btn.dataset.mobFirstFrame : +btn.dataset.firstFrame;
    const lastFrame = breakpointSm.matches ? +btn.dataset.mobLastFrame : +btn.dataset.lastFrame;
    let activeFrames;
    let callback = btn.dataset.sequenceCallback;

    wrapper.classList.add('no-pointer');

    framesArr.forEach((frame, i) => {
      if (btnId in frame) {
        activeFrames = framesArr[i];
        return;
      }
    });

    if (callback === 'showIntro') {
      btn.classList.add('hide-animate');

      setTimeout(() => {
        showIntroText();
      }, 1000);
    }

    if (callback === 'showBook') {
      showBook();
    }

    launchSequence(activeFrames, firstFrame, lastFrame, context, canvas, () => {
      activeFrame = Object.values(activeFrames)[0][lastFrame];
      wrapper.classList.remove('no-pointer');

      if (callback === 'showIntro') {
        document.location.href = `${String(window.location).split('#').shift()}#about`;
      }

      if (callback === 'showBook') {
        openBook.classList.add('show');
        const videoBtn = document.querySelector('.main-nav__link[data-open-modal="video-sport"]');
        if (videoBtn) {
          openWithVideo(videoBtn);
        modals.open('video-sport');
        }

        if (breakpointSm.matches) {
          intro.classList.add('is-hidden');
        }
      }
    });
  });
};

const createSequenceAction = (item) => {
  const id = item.dataset.sequenceId;
  const canvas = item.querySelector('[data-sequence-canvas]');
  const context = canvas.getContext('2d');
  const frameCount = breakpointSm.matches ? +item.dataset.mobSequenceFrames : +item.dataset.sequenceFrames;
  const sequencePath = breakpointSm.matches ? item.dataset.mobSequencePath : item.dataset.sequencePath;
  const imageFormat = item.dataset.imageFormat;
  const frames = [];

  const dpr = window.devicePixelRatio || 1;
  context.scale(dpr, dpr);

  let framesLoaded = 0;

  let scrollTop;
  let maxScrollTop;
  let scrollFraction;
  let frameIndex = 1;
  activeFrame = frames[frameIndex];

  const resizedParent = canvas.parentElement;

  const hash = window.location.hash.replace('#', '');
  const isAbout = hash === 'about';

  canvas.width = resizedParent.offsetWidth * dpr;
  canvas.height = resizedParent.offsetHeight * dpr;

  preloadImages(frames, frameCount, sequencePath, imageFormat, framesLoaded, () => {
    let isContain = false;
    if (item.dataset.scrollSequence) {
      isContain = true;
    }
    updateImage(frameIndex, frames, context, canvas, isContain);
    activeFrame = frames[frameIndex];
    isLoaded = true;
    framesArr.push({ [id]: frames });
    if (isAbout) {
      if (!breakpointSm.matches) {
        updateImage(171, frames, context, canvas);
        activeFrame = frames[171];
      } else {
        updateImage(102, frames, context, canvas);
        activeFrame = frames[102];
      }
    }
  });

  const onWindowResize = () => {
    if (!isLoaded) {
      return;
    }

    const scale = getImageScale(canvas, activeFrame);

    canvas.width = resizedParent.offsetWidth * dpr;
    canvas.height = resizedParent.offsetHeight * dpr;

    const imgWidth = activeFrame.width;
    const imgHeight = activeFrame.height;
    const offsetY = 0;
    const offsetX = canvas.width - imgWidth * scale;

    context.drawImage(activeFrame, offsetX / 2, offsetY, imgWidth * scale, imgHeight * scale);
  };

  const onWindowScroll = () => {
    if (!isLoaded) {
      return;
    }

    if (seqWrapper) {
      const offset = breakpointSm.matches ? 1 : 2;
      scrollTop = seqWrapper.getBoundingClientRect().top * -1;
      maxScrollTop = seqWrapper.scrollHeight - window.innerHeight * offset;
      scrollFraction = scrollTop / maxScrollTop;
      frameIndex = Math.min(
          frameCount - 1,
          Math.ceil(scrollFraction * frameCount)
      );
    }
    if (scrollFraction <= 1 && scrollFraction > 0) {
      const isContain = true;
      requestAnimationFrame(() => updateImage(frameIndex + 1, frames, context, canvas, isContain));
      activeFrame = frames[frameIndex + 1];
    }
  };

  if (item.dataset.launchSequence) {
    wrapper.classList.add('no-pointer');
    introBg1.classList.add('show');

    if (isAbout) {
      introBg1.classList.remove('show');
      introBg2.classList.add('show');
      nextBtns.forEach((btn) => {
        btn.classList.remove('is-next-show');
      });
      wrapper.classList.remove('no-pointer');
      showIntroText();
    }
  }

  if (item.dataset.scrollSequence) {
    scrollTop = seqWrapper.getBoundingClientRect().top * -1;
    maxScrollTop = seqWrapper.scrollHeight - window.innerHeight;
    scrollFraction = scrollTop / maxScrollTop;
    frameIndex = Math.min(
        frameCount - 1,
        Math.ceil(scrollFraction * frameCount)
    );

    frameIndex = frameIndex < 0 ? 1 : frameIndex;
    const isContain = true;
    updateImage(frameIndex, frames, context, canvas, isContain);
    window.addEventListener('scroll', onWindowScroll);
  }

  const breakpointChecker = () => {
    location.reload();
  };

  breakpointSm.addListener(breakpointChecker);

  window.addEventListener('resize', onWindowResize);
};

export default class Sequence {
  constructor() {
    window.sequenceInit = this.init.bind(this);
  }

  setAction(item) {
    setSequenceAction(item);
    return item;
  }

  createSequence(item) {
    createSequenceAction(item);
    return item;
  }

  init() {
    const sequences = document.querySelectorAll('[data-sequence]');
    sequences.forEach((sequence) => {
      this.createSequence(sequence);
    });

    const buttons = document.querySelectorAll('[data-sequence-btn]');
    buttons.forEach((btn) => {
      this.setAction(btn);
    });
  }
}

const initSequence = () => {
  const sequence = new Sequence();
  sequence.init();
};

export {initSequence, launchStartSequence, framesArr, launchSequence};
