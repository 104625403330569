const dataImg = [
  {
    id: '10',
    keys: [
      {
        x: 575,
        y: 218,
        width: 154,
        deg: 0,
      },
      {
        x: 624,
        y: 940,
        width: 141,
        deg: 0,
      },
      {
        x: 435,
        y: 452,
        width: 112,
        deg: 0,
      },
      {
        x: 156,
        y: 246,
        width: 88,
        deg: 0,
      },
      {
        x: 766,
        y: 1142,
        width: 88,
        deg: 0,
      }
    ],
    firstName: {
      x: 429,
      y: 538,
      width: 303,
      deg: 0,
    },
    secondName: {
      x: 358,
      y: 774,
      width: 270,
      deg: 0,
    },
    date: {
      x: 399,
      y: 935,
      width: 158,
      deg: 0,
    },
  },
  {
    id: '11',
    keys: [
      {
        x: 317,
        y: 115,
        width: 154,
        deg: 0,
      },
      {
        x: 22,
        y: 469,
        width: 141,
        deg: 0,
      },
      {
        x: 300,
        y: 786,
        width: 112,
        deg: 0,
      },
      {
        x: 221,
        y: 1158,
        width: 88,
        deg: 0,
      },
      {
        x: 533,
        y: 651,
        width: 89,
        deg: 0,
      }
    ],
    firstName: {
      x: 264,
      y: 227,
      width: 305,
      deg: 0,
    },
    secondName: {
      x: 213,
      y: 679,
      width: 268,
      deg: 0,
    },
    date: {
      x: 573,
      y: 377,
      width: 106,
      deg: 0,
    },
  },
  {
    id: '12',
    keys: [
      {
        x: 254,
        y: 65,
        width: 104,
        deg: 0,
      },
      {
        x: 346,
        y: 1023,
        width: 139,
        deg: -5.5,
      },
      {
        x: 197,
        y: 948,
        width: 145,
        deg: -90,
      },
      {
        x: 166,
        y: 350,
        width: 81,
        deg: 0,
      },
      {
        x: 267,
        y: 131,
        width: 85,
        deg: 0,
      }
    ],
    firstName: {
      x: 210,
      y: 563,
      width: 208,
      deg: 0,
    },
    secondName: {
      x: 197,
      y: 405,
      width: 178,
      deg: 0,
    },
    date: {
      x: 217,
      y: 690,
      width: 85,
      deg: 0,
    },
  },
  {
    id: '13',
    keys: [
      {
        x: 592,
        y: 683,
        width: 143,
        deg: 0,
      },
      {
        x: 183,
        y: 173,
        width: 164,
        deg: 0,
      },
      {
        x: 315,
        y: 693,
        width: 79,
        deg: 0,
      },
      {
        x: 601,
        y: 774,
        width: 92,
        deg: 0,
      },
      {
        x: 437,
        y: 124,
        width: 141,
        deg: 0,
      }
    ],
    firstName: {
      x: 474,
      y: 394,
      width: 190,
      deg: 0,
    },
    secondName: {
      x: 361,
      y: 583,
      width: 178,
      deg: 0,
    },
    date: {
      x: 274,
      y: 815,
      width: 74,
      deg: 0,
    },
  },
  {
    id: '6',
    keys: [
      {
        x: 867,
        y: 478,
        width: 154,
        deg: 0,
      },
      {
        x: 152,
        y: 564,
        width: 103,
        deg: 0,
      },
      {
        x: 608,
        y: 73,
        width: 115,
        deg: 0,
      },
      {
        x: 934,
        y: 565,
        width: 107,
        deg: 0,
      },
      {
        x: 482,
        y: 829,
        width: 77,
        deg: 0,
      }
    ],
    firstName: {
      x: 263,
      y: 166,
      width: 303,
      deg: 0,
    },
    secondName: {
      x: 177,
      y: 368,
      width: 199,
      deg: 0,
    },
    date: {
      x: 403,
      y: 32,
      width: 107,
      deg: 0,
    },
  },
  {
    id: '1',
    keys: [
      {
        x: 149,
        y: 608,
        width: 154,
        deg: -90,
      },
      {
        x: 322,
        y: 834,
        width: 111,
        deg: 75,
      },
      {
        x: 28,
        y: 489,
        width: 77,
        deg: 0,
      },
      {
        x: 363,
        y: 559,
        width: 85,
        deg: 0,
      },
      {
        x: 113,
        y: 836,
        width: 63,
        deg: 90,
      }
    ],
    firstName: {
      x: 114,
      y: 316,
      width: 276,
      deg: 0,
    },
    secondName: {
      x: 108,
      y: 196,
      width: 136,
      deg: 0,
    },
    date: {
      x: 182,
      y: 52,
      width: 94,
      deg: 0,
    },
  },
  {
    id: '18',
    keys: [
      {
        x: 32,
        y: 55,
        width: 141,
        deg: 0,
      },
      {
        x: 465,
        y: 570,
        width: 148,
        deg: 0,
      },
      {
        x: 239,
        y: 868,
        width: 113,
        deg: 0,
      },
      {
        x: 783,
        y: 1007,
        width: 102,
        deg: 0,
      },
      {
        x: 24,
        y: 191,
        width: 88,
        deg: 0,
      }
    ],
    firstName: {
      x: 280,
      y: 659,
      width: 153,
      deg: 0,
    },
    secondName: {
      x: 159,
      y: 981,
      width: 187,
      deg: 0,
    },
    date: {
      x: 190,
      y: 437,
      width: 109,
      deg: 0,
    },
  },
  {
    id: '4',
    keys: [
      {
        x: 317,
        y: 1005,
        width: 174,
        deg: 0,
      },
      {
        x: 33,
        y: 1169,
        width: 92,
        deg: 0,
      },
      {
        x: 832,
        y: 460,
        width: 77,
        deg: 0,
      },
      {
        x: 660,
        y: 451,
        width: 99,
        deg: 0,
      },
      {
        x: 135,
        y: 987,
        width: 80,
        deg: 0,
      }
    ],
    firstName: {
      x: 305,
      y: 871,
      width: 212,
      deg: 0,
    },
    secondName: {
      x: 207,
      y: 308,
      width: 159,
      deg: 0,
    },
    date: {
      x: 57,
      y: 1103,
      width: 89,
      deg: 0,
    },
  },
  {
    id: '14',
    keys: [
      {
        x: 526,
        y: 737,
        width: 123,
        deg: 0,
      },
      {
        x: 548,
        y: 153,
        width: 102,
        deg: 0,
      },
      {
        x: 396,
        y: 70,
        width: 109,
        deg: 0,
      },
      {
        x: 24,
        y: 921,
        width: 164,
        deg: 0,
      },
      {
        x: 595,
        y: 1147,
        width: 80,
        deg: 0,
      }
    ],
    firstName: {
      x: 457,
      y: 395,
      width: 202,
      deg: 0,
    },
    secondName: {
      x: 435,
      y: 516,
      width: 211,
      deg: 0,
    },
    date: {
      x: 542,
      y: 797,
      width: 116,
      deg: 0,
    },
  },
  {
    id: '15',
    keys: [
      {
        x: 681,
        y: 481,
        width: 81,
        deg: 0,
      },
      {
        x: 546,
        y: 723,
        width: 93,
        deg: 0,
      },
      {
        x: 372,
        y: 972,
        width: 87,
        deg: 45,
      },
      {
        x: 432,
        y: 848,
        width: 58,
        deg: 45,
      },
      {
        x: 905,
        y: 348,
        width: 37,
        deg: 0,
      }
    ],
    firstName: {
      x: 597,
      y: 216,
      width: 221,
      deg: 0,
    },
    secondName: {
      x: 604,
      y: 356,
      width: 237,
      deg: 0,
    },
    date: {
      x: 464,
      y: 317,
      width: 57,
      deg: 0,
    },
  },
  {
    id: '16-1',
    keys: [
      {
        x: 431,
        y: 686,
        width: 120,
        deg: 18.95,
      },
      {
        x: 587,
        y: 33,
        width: 84,
        deg: 0,
      },
      {
        x: 766,
        y: 276,
        width: 87,
        deg: 0,
      },
      {
        x: 477,
        y: 332,
        width: 68,
        deg: 90,
      },
      {
        x: 933,
        y: 563,
        width: 62,
        deg: -69.55,
      }
    ],
    firstName: {
      x: 524,
      y: 472,
      width: 173,
      deg: 0,
    },
    secondName: {
      x: 678,
      y: 405,
      width: 106,
      deg: 0,
    },
    date: {
      x: 1069,
      y: 443,
      width: 60,
      deg: 0,
    },
  },
  {
    id: '26',
    keys: [
      {
        x: 36,
        y: 475,
        width: 104,
        deg: 0,
      },
      {
        x: 354,
        y: 577,
        width: 92,
        deg: 0,
      },
      {
        x: 560,
        y: 379,
        width: 103,
        deg: 0,
      },
      {
        x: 441,
        y: 521,
        width: 83,
        deg: 0,
      },
      {
        x: 947,
        y: 350,
        width: 33,
        deg: 9.33,
      }
    ],
    firstName: {
      x: 199,
      y: 444,
      width: 126,
      deg: 0,
    },
    secondName: {
      x: 458,
      y: 459,
      width: 129,
      deg: 0,
    },
    date: {
      x: 26,
      y: 539,
      width: 37,
      deg: 0,
    },
  },
  {
    id: '27',
    keys: [
      {
        x: 703,
        y: 504,
        width: 102,
        deg: 0,
      },
      {
        x: 1137,
        y: 571,
        width: 64,
        deg: 0,
      },
      {
        x: 744,
        y: 209,
        width: 89,
        deg: 0,
      },
      {
        x: 592,
        y: 72,
        width: 64,
        deg: 0,
      },
      {
        x: 230,
        y: 519,
        width: 71,
        deg: -9.53,
      }
    ],
    firstName: {
      x: 100,
      y: 389,
      width: 140,
      deg: 0,
    },
    secondName: {
      x: 749,
      y: 280,
      width: 112,
      deg: 0,
    },
    date: {
      x: 172,
      y: 325,
      width: 37,
      deg: 0,
    },
  },
  {
    id: '16',
    keys: [
      {
        x: 767,
        y: 237,
        width: 111,
        deg: 0,
      },
      {
        x: 288,
        y: 439,
        width: 85,
        deg: 0,
      },
      {
        x: 970,
        y: 498,
        width: 89,
        deg: 0,
      },
      {
        x: 823,
        y: 444,
        width: 49,
        deg: 0,
      },
      {
        x: 630,
        y: 205,
        width: 38,
        deg: 0,
      }
    ],
    firstName: {
      x: 498,
      y: 439,
      width: 233,
      deg: 0,
    },
    secondName: {
      x: 571,
      y: 52,
      width: 133,
      deg: 0,
    },
    date: {
      x: 1117,
      y: 546,
      width: 83,
      deg: 0,
    },
  },
  {
    id: '19',
    keys: [
      {
        x: 76,
        y: 577,
        width: 99,
        deg: 0,
      },
      {
        x: 410,
        y: 657,
        width: 130,
        deg: 0,
      },
      {
        x: 227,
        y: 312,
        width: 64,
        deg: 0,
      },
      {
        x: 133,
        y: 288,
        width: 41,
        deg: 0,
      },
      {
        x: 357,
        y: 949,
        width: 42,
        deg: 13.79,
      }
    ],
    firstName: {
      x: 563,
      y: 277,
      width: 121,
      deg: 30,
    },
    secondName: {
      x: 213,
      y: 481,
      width: 136,
      deg: 0,
    },
    date: {
      x: 440,
      y: 889,
      width: 55,
      deg: 0,
    },
  },
  {
    id: '20',
    keys: [
      {
        x: 751,
        y: 952,
        width: 131,
        deg: 0,
      },
      {
        x: 388,
        y: 819,
        width: 194,
        deg: 0,
      },
      {
        x: 340,
        y: 1074,
        width: 98,
        deg: 0,
      },
      {
        x: 708,
        y: 1115,
        width: 147,
        deg: 0,
      },
      {
        x: 38,
        y: 325,
        width: 78,
        deg: 0,
      }
    ],
    firstName: {
      x: 47,
      y: 121,
      width: 230,
      deg: 0,
    },
    secondName: {
      x: 673,
      y: 483,
      width: 136,
      deg: 0,
    },
    date: {
      x: 664,
      y: 796,
      width: 117,
      deg: 0,
    },
  },
  {
    id: '21',
    keys: [
      {
        x: 175,
        y: 908,
        width: 111,
        deg: 0,
      },
      {
        x: 474,
        y: 821,
        width: 83,
        deg: 0,
      },
      {
        x: 686,
        y: 215,
        width: 91,
        deg: 0,
      },
      {
        x: 867,
        y: 110,
        width: 89,
        deg: 31.72,
      },
      {
        x: 449,
        y: 1010,
        width: 95,
        deg: 0,
      }
    ],
    firstName: {
      x: 224,
      y: 682,
      width: 165,
      deg: 0,
    },
    secondName: {
      x: 449,
      y: 398,
      width: 187,
      deg: 0,
    },
    date: {
      x: 597,
      y: 165,
      width: 73,
      deg: 0,
    },
  },
  {
    id: '8',
    keys: [
      {
        x: 683,
        y: 1144,
        width: 63,
        deg: 0,
      },
      {
        x: 628,
        y: 145,
        width: 81,
        deg: 0,
      },
      {
        x: 790,
        y: 418,
        width: 79,
        deg: 0,
      },
      {
        x: 331,
        y: 407,
        width: 63,
        deg: 0,
      },
      {
        x: 343,
        y: 689,
        width: 59,
        deg: 90,
      }
    ],
    firstName: {
      x: 708,
      y: 169,
      width: 126,
      deg: 0,
    },
    secondName: {
      x: 643,
      y: 342,
      width: 198,
      deg: 0,
    },
    date: {
      x: 784,
      y: 718,
      width: 75,
      deg: -36.34,
    },
  },
  {
    id: '24',
    keys: [
      {
        x: 647,
        y: 120,
        width: 75,
        deg: 0,
      },
      {
        x: 408,
        y: 866,
        width: 75,
        deg: 39.7,
      },
      {
        x: 701,
        y: 633,
        width: 93,
        deg: 0,
      },
      {
        x: 513,
        y: 244,
        width: 82,
        deg: 0,
      }
    ],
    firstName: {
      x: 640,
      y: 377,
      width: 136,
      deg: 0,
    },
    secondName: {
      x: 398,
      y: 43,
      width: 103,
      deg: 0,
    },
    date: {
      x: 621,
      y: 548,
      width: 51,
      deg: 0,
    },
  },
  {
    id: '23',
    keys: [
      {
        x: 433,
        y: 148,
        width: 64,
        deg: 0,
      },
      {
        x: 602,
        y: 334,
        width: 68,
        deg: 0,
      },
      {
        x: 459,
        y: 456,
        width: 82,
        deg: 0,
      },
      {
        x: 803,
        y: 946,
        width: 79,
        deg: 0,
      },
      {
        x: 258,
        y: 912,
        width: 50,
        deg: 0,
      }
    ],
    firstName: {
      x: 365,
      y: 31,
      width: 117,
      deg: 0,
    },
    secondName: {
      x: 769,
      y: 249,
      width: 78,
      deg: 0,
    },
    date: {
      x: 280,
      y: 687,
      width: 66,
      deg: 0,
    },
  },
  {
    id: '25',
    keys: [
      {
        x: 327,
        y: 312,
        width: 48,
        deg: 0,
      },
      {
        x: 41,
        y: 1064,
        width: 64,
        deg: -7.79,
      },
      {
        x: 369,
        y: 765,
        width: 47,
        deg: 0,
      },
      {
        x: 413,
        y: 950,
        width: 52,
        deg: 0,
      },
      {
        x: 248,
        y: 1119,
        width: 42,
        deg: -5.06,
      }
    ],
    firstName: {
      x: 341,
      y: 449,
      width: 63,
      deg: 0,
    },
    secondName: {
      x: 297,
      y: 731,
      width: 116,
      deg: 0,
    },
    date: {
      x: 315,
      y: 224,
      width: 48,
      deg: 0,
    },
  },
  {
    id: '28',
    keys: [
      {
        x: 123,
        y: 126,
        width: 92,
        deg: 0,
      },
      {
        x: 951,
        y: 362,
        width: 82,
        deg: 0,
      },
      {
        x: 204,
        y: 355,
        width: 70,
        deg: 0,
      },
      {
        x: 465,
        y: 355,
        width: 69,
        deg: 0,
      },
      {
        x: 236,
        y: 177,
        width: 46,
        deg: 0,
      }
    ],
    firstName: {
      x: 718,
      y: 259,
      width: 105,
      deg: 0,
    },
    secondName: {
      x: 924,
      y: 290,
      width: 96,
      deg: 0,
    },
    date: {
      x: 434,
      y: 99,
      width: 51,
      deg: 0,
    },
  },
  {
    id: '29',
    keys: [
      {
        x: 35,
        y: 375,
        width: 102,
        deg: 0,
      },
      {
        x: 275,
        y: 52,
        width: 107,
        deg: 0,
      },
      {
        x: 514,
        y: 547,
        width: 100,
        deg: -45,
      },
      {
        x: 636,
        y: 103,
        width: 104,
        deg: 0,
      },
      {
        x: 349,
        y: 1070,
        width: 48,
        deg: 0,
      }
    ],
    firstName: {
      x: 258,
      y: 250,
      width: 147,
      deg: 0,
    },
    secondName: {
      x: 480,
      y: 395,
      width: 160,
      deg: 0,
    },
    date: {
      x: 420,
      y: 763,
      width: 90,
      deg: 0,
    },
  },
  {
    id: '30',
    keys: [
      {
        x: 489,
        y: 846,
        width: 107,
        deg: 0,
      },
      {
        x: 305,
        y: 144,
        width: 103,
        deg: 0,
      },
      {
        x: 603,
        y: 1169,
        width: 76,
        deg: 0,
      },
      {
        x: 190,
        y: 1098,
        width: 79,
        deg: 0,
      },
      {
        x: 83,
        y: 498,
        width: 45,
        deg: 0,
      }
    ],
    firstName: {
      x: 191,
      y: 196,
      width: 120,
      deg: 0,
    },
    secondName: {
      x: 316,
      y: 195,
      width: 115,
      deg: 0,
    },
    date: {
      x: 371,
      y: 746,
      width: 76,
      deg: 0,
    },
  },
  {
    id: '31',
    keys: [
      {
        x: 15,
        y: 274,
        width: 54,
        deg: 0,
      },
      {
        x: 282,
        y: 236,
        width: 41,
        deg: 0,
      },
      {
        x: 422,
        y: 328,
        width: 43,
        deg: 0,
      },
      {
        x: 804,
        y: 449,
        width: 114,
        deg: 0,
      },
      {
        x: 611,
        y: 874,
        width: 60,
        deg: 0,
      }
    ],
    firstName: {
      x: 196,
      y: 439,
      width: 134,
      deg: 0,
    },
    secondName: {
      x: 248,
      y: 574,
      width: 79,
      deg: 0,
    },
    date: {
      x: 823,
      y: 745,
      width: 48,
      deg: 0,
    },
  },
  {
    id: '32',
    keys: [
      {
        x: 20,
        y: 268,
        width: 91,
        deg: 0,
      },
      {
        x: 321,
        y: 553,
        width: 78,
        deg: 0,
      },
      {
        x: 649,
        y: 461,
        width: 83,
        deg: 0,
      },
      {
        x: 454,
        y: 859,
        width: 62,
        deg: 0,
      },
      {
        x: 851,
        y: 1125,
        width: 49,
        deg: 0,
      }
    ],
    firstName: {
      x: 726,
      y: 138,
      width: 113,
      deg: 0,
    },
    secondName: {
      x: 543,
      y: 790,
      width: 164,
      deg: 0,
    },
    date: {
      x: 743,
      y: 998,
      width: 58,
      deg: 0,
    },
  },
  {
    id: '33',
    keys: [
      {
        x: 163,
        y: 592,
        width: 46,
        deg: 0,
      },
      {
        x: 393,
        y: 729,
        width: 54,
        deg: 0,
      },
      {
        x: 519,
        y: 1123,
        width: 101,
        deg: 0,
      },
      {
        x: 483,
        y: 1210,
        width: 101,
        deg: 19.39,
      },
      {
        x: 378,
        y: 856,
        width: 64,
        deg: 0,
      }
    ],
    firstName: {
      x: 442,
      y: 706,
      width: 90,
      deg: 0,
    },
    secondName: {
      x: 381,
      y: 1009,
      width: 177,
      deg: 0,
    },
    date: {
      x: 608,
      y: 384,
      width: 34,
      deg: 0,
    },
  },
  {
    id: '34',
    keys: [
      {
        x: 738,
        y: 103,
        width: 123,
        deg: 0,
      },
      {
        x: 871,
        y: 888,
        width: 127,
        deg: -90,
      },
      {
        x: 998,
        y: 471,
        width: 144,
        deg: 0,
      },
      {
        x: 196,
        y: 1195,
        width: 93,
        deg: 0,
      },
      {
        x: 727,
        y: 220,
        width: 134,
        deg: -26.67,
      }
    ],
    firstName: {
      x: 587,
      y: 601,
      width: 247,
      deg: 66.48,
    },
    secondName: {
      x: 856,
      y: 1195,
      width: 203,
      deg: 0,
    },
    date: {
      x: 1162,
      y: 344,
      width: 50,
      deg: 0,
    },
  },
  {
    id: '35',
    keys: [
      {
        x: 585,
        y: 327,
        width: 64,
        deg: 0,
      },
      {
        x: 609,
        y: 566,
        width: 65,
        deg: 0,
      },
      {
        x: 199,
        y: 729,
        width: 91,
        deg: 0,
      },
      {
        x: 372,
        y: 699,
        width: 94,
        deg: 0,
      },
      {
        x: 353,
        y: 911,
        width: 88,
        deg: 0,
      }
    ],
    firstName: {
      x: 343,
      y: 727,
      width: 124,
      deg: 0,
    },
    secondName: {
      x: 543,
      y: 388,
      width: 126,
      deg: 0,
    },
    date: {
      x: 172,
      y: 480,
      width: 36,
      deg: -39.94,
    },
  },
  {
    id: '7',
    keys: [
      {
        x: 356,
        y: 1133,
        width: 128,
        deg: 0,
      },
      {
        x: 431,
        y: 796,
        width: 109,
        deg: 0,
      },
      {
        x: 563,
        y: 159,
        width: 44,
        deg: 0,
      },
      {
        x: 848,
        y: 1101,
        width: 84,
        deg: 0,
      },
      {
        x: 574,
        y: 307,
        width: 71,
        deg: 0,
      }
    ],
    firstName: {
      x: 343,
      y: 727,
      width: 124,
      deg: 0,
    },
    secondName: {
      x: 669,
      y: 712,
      width: 130,
      deg: 0,
    },
    date: {
      x: 550,
      y: 393,
      width: 138,
      deg: 0,
    },
  },
  {
    id: '5',
    keys: [
      {
        x: 94,
        y: 198,
        width: 146,
        deg: 0,
      },
      {
        x: 209,
        y: 613,
        width: 112,
        deg: 0,
      },
      {
        x: 345,
        y: 859,
        width: 131,
        deg: 10.95,
      },
      {
        x: 558,
        y: 142,
        width: 81,
        deg: 0,
      },
      {
        x: 6,
        y: 1125,
        width: 50,
        deg: 0,
      }
    ],
    firstName: {
      x: 227,
      y: 632,
      width: 120,
      deg: 0,
    },
    secondName: {
      x: 436,
      y: 353,
      width: 125,
      deg: 0,
    },
    date: {
      x: 637,
      y: 1121,
      width: 69,
      deg: 0,
    },
  },
  {
    id: '22',
    keys: [
      {
        x: 287,
        y: 612,
        width: 90,
        deg: 0,
      },
      {
        x: 209,
        y: 613,
        width: 112,
        deg: 0,
      },
      {
        x: 207,
        y: 243,
        width: 41,
        deg: 0,
      },
      {
        x: 554,
        y: 727,
        width: 48,
        deg: 0,
      },
      {
        x: 243,
        y: 1140,
        width: 81,
        deg: 0,
      }
    ],
    firstName: {
      x: 240,
      y: 670,
      width: 102,
      deg: 0,
    },
    // secondName: {
    //   x: 436,
    //   y: 353,
    //   width: 125,
    //   deg: 0,
    // },
    date: {
      x: 508,
      y: 493,
      width: 55,
      deg: 0,
    },
  },
  {
    id: '2',
    keys: [
      {
        x: 523,
        y: 1128,
        width: 62,
        deg: 0,
      },
      {
        x: 106,
        y: 464,
        width: 67,
        deg: 0,
      },
      {
        x: 317,
        y: 940,
        width: 56,
        deg: 0,
      },
      {
        x: 143,
        y: 920,
        width: 95,
        deg: 0,
      },
      {
        x: 75,
        y: 1152,
        width: 59,
        deg: 0,
      }
    ],
    firstName: {
      x: 308,
      y: 522,
      width: 206,
      deg: 0,
    },
    secondName: {
      x: 176,
      y: 806,
      width: 175,
      deg: 0,
    },
    date: {
      x: 264,
      y: 1043,
      width: 78,
      deg: 0,
    },
  },
  {
    id: '3',
    keys: [
      {
        x: 89,
        y: 970,
        width: 59,
        deg: 0,
      },
      {
        x: 555,
        y: 810,
        width: 99,
        deg: 0,
      },
      {
        x: 53,
        y: 473,
        width: 73,
        deg: 0,
      },
      {
        x: 85,
        y: 174,
        width: 81,
        deg: 0,
      },
      {
        x: 30,
        y: 316,
        width: 94,
        deg: 0,
      }
    ],
    firstName: {
      x: 347,
      y: 554,
      width: 193,
      deg: 0,
    },
    secondName: {
      x: 98,
      y: 729,
      width: 121,
      deg: 0,
    },
    date: {
      x: 609,
      y: 907,
      width: 68,
      deg: 27.38,
    },
  },
  {
    id: '9',
    keys: [
      {
        x: 322,
        y: 362,
        width: 92,
        deg: -4.51,
      },
      {
        x: 498,
        y: 1023,
        width: 156,
        deg: 0,
      },
      {
        x: 481,
        y: 597,
        width: 66,
        deg: -105.14,
      },
      {
        x: 730,
        y: 308,
        width: 44,
        deg: 0,
      },
      {
        x: 536,
        y: 897,
        width: 113,
        deg: 0,
      }
    ],
    firstName: {
      x: 287,
      y: 224,
      width: 92,
      deg: -14.28,
    },
    secondName: {
      x: 383,
      y: 32,
      width: 148,
      deg: 0,
    },
    date: {
      x: 521,
      y: 807,
      width: 124,
      deg: 0,
    },
  }
];

export  {dataImg};
