const initModalStarsStoriesSlider = () => {
  const parents = document.querySelectorAll('[data-modal-slider="parent"]');

  if (!parent.length) {
    return;
  }

  parents.forEach((parent) => {
  const slider = parent.querySelector('[data-modal-slider="main"]');
  const thumbs = parent.querySelector('[data-modal-slider="thumbs"]');

  const nextBtn = parent.querySelector('.modal-timeline-slider__button--next');
  const prevBtn = parent.querySelector('.modal-timeline-slider__button--prev');

  const swiper = new Swiper(thumbs, {
    spaceBetween: 13.26,
    slidesPerView: 'auto',
    watchSlidesProgress: true,
  });

  const swiper2 = new Swiper(slider, {
    spaceBetween: 10,
    navigation: {
      nextEl: nextBtn,
      prevEl: prevBtn,
    },
    thumbs: {
      swiper: swiper,
    },
  });
  });
};

export {initModalStarsStoriesSlider};
