const sequenceWrapper = document.querySelector('.intro-encyclopedia__sequence-wrapper');
const sequenceElement = document.querySelector('.intro-encyclopedia__sequence');
const bottomText = document.querySelector('.intro-encyclopedia__bottom');
const breakpointSm = window.matchMedia('(max-width:767px)');
const offset = 300;

let animation;

const showText = () => {
  if (bottomText.classList.contains('hidden')) {
    bottomText.classList.remove('hidden');
    bottomText.classList.add('show');
  }
};

const hideText = () => {
  if (!bottomText.classList.contains('hidden')) {
    bottomText.classList.add('hidden');
    bottomText.classList.remove('show');
  }
};


const createAnimation = () => {
  const stopPosition = window.innerHeight < bottomText.getBoundingClientRect().height ? bottomText.getBoundingClientRect().height : window.innerHeight;
  animation = window.gsap.to(sequenceElement, {
    scrollTrigger: {
      trigger: sequenceWrapper,
      start: `bottom-=${stopPosition * 2}`,
      end: `bottom-=${stopPosition}`,
      scrub: true,
    },
    xPercent: -50,
  });
};


const onWindowScroll = () => {
  console.log(bottomText.getBoundingClientRect().top, window.innerWidth * 0.6);
  if (bottomText.getBoundingClientRect().top < window.innerWidth * 0.6) {
    showText();
  } else {
    hideText();
  }
};

const breakpointChecker = () => {
  if (!breakpointSm.matches) {
    createAnimation();
    window.addEventListener('scroll', onWindowScroll);
    hideText();
  } else {
    window.removeEventListener('scroll', onWindowScroll);
    showText();
    sequenceElement.style = null;

    if (animation) {
      animation.kill();
    }
  }
};

const initEncyclopediaAnimation = () => {
  if (!sequenceWrapper) {
    return;
  }

  breakpointChecker();
  breakpointSm.addListener(breakpointChecker);
};

export {initEncyclopediaAnimation};
