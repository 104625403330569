const initHistorySlider = () => {
  const sliders = document.querySelectorAll('.history-slider');

  if (!sliders.length) {
    return;
  }

  sliders.forEach((slider) => {
    const mainSlider = slider.querySelector('.history-slider__main');
    const thumbSlider = slider.querySelector('.history-slider__thumbs');

    const nextBtn = slider.querySelector('.history-slider__control--next');
    const prevBtn = slider.querySelector('.history-slider__control--prev');

    const swiper = new Swiper(thumbSlider, {
      spaceBetween: 12,
      slidesPerView: 'auto',
    });
    const swiper2 = new Swiper(mainSlider, {
      spaceBetween: 40,
      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },
      thumbs: {
        swiper: swiper,
      },
    });
  });
};

export {initHistorySlider};
