import {Modals} from './modals';

let modals;

// Здесь реализован пример открытия модалки через колбэк закрытия
// const openModalInCloseCallback = (name, context = this) => {
//   context._enableScrolling = false;
//   context._setSettings('default');
//   modals.open(name);
// };

// closeCallback() {
//   openModalInCloseCallback('modal-5');
// },

const setScrollLock = () => {
  const header = document.querySelector('.header');
  if (header.classList.contains('is-open')) {
    setTimeout(() => {
      window.scrollLock.disableScrolling();
    }, 410);
  }
};

const scrollModal = () => {
  const timelineModal = document.querySelector('.modal-timeline');
  if (!timelineModal) {
    return;
  }
  if (timelineModal.getBoundingClientRect().top < 0) {
    timelineModal.scrollIntoView();
  }
};

const settings = {
  'default': {
    preventDefault: true,
    stopPlay: true,
    lockFocus: true,
    startFocus: true,
    focusBack: true,
    eventTimeout: 400,
    openCallback: false,
    closeCallback: false,
  },
  'copyright': {
    closeCallback: setScrollLock,
  },
  'timeline': {
    openCallback: scrollModal,
  },
};

const initModals = () => {
  const modalElements = document.querySelectorAll('.modal');
  if (modalElements.length) {
    modalElements.forEach((el) => {
      setTimeout(() => {
        el.classList.remove('modal--preload');
      }, 100);
    });
  }

  modals = new Modals(settings);
  // Используйте в разработке экспортируемую переменную modals, window сделан для бэкэнда
  window.modals = modals;
};

export {modals, initModals};
