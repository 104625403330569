import {dataImg} from './data-img';
import {customImgTemplate} from './data/templates/custom-img-templates';
import { Canvg } from 'canvg';

const initCustomImg = () => {
  const customImgRadio = document.querySelectorAll('[data-custom-img-radio]');
  const customImgBlock = document.querySelector('[data-custom-img]');
  const customImgBlockHidden = document.querySelector('[data-custom-img-hidden]');
  const customImgBackBlock = document.querySelector('[data-custom-back-img]');
  const customImgDataBlocks = document.querySelectorAll('[ data-for-custom-img]');
  const customImgCanvas = document.querySelector('[data-custom-img-canvas]');

  if (customImgRadio.length === 0) {
    return;
  }

  let fs = 100;
  let maxFsBlockWidth = 100;
  const setFsBlock = document.querySelector('[data-get-fs]');
  const getImgWidthBlock = document.querySelector('[data-get-naturalWidth-img]');

  const setFs = () => {
    if (setFsBlock.clientWidth > maxFsBlockWidth) {
      fs = fs -1;
      setFsBlock.style = `font-size: ${fs}px`;
      setFs();
    }
  };

  const addTextToSvg = (text, coords) => {
    fs = 100;
    const {x, y, width, deg} = coords;
    const additionalAttributes = deg !== 0 ? 'text-anchor="middle" dominant-baseline="central"' : '';
    const additionalX = deg !== 0 ? x : 0;
    const additionalY = deg !== 0 ? y : 0;
    const transformedText = text.toUpperCase();
    setFsBlock.innerHTML = transformedText;
    maxFsBlockWidth = width;
    setFsBlock.style = `font-size: ${fs}px`;
    setFs();
    const strokeWidth = fs / 10 < 1 ? 1 : fs / 10;
    const svgText = `  <text x="${x}" y="${y}" ${additionalAttributes} transform="translate(0, 0) rotate(${deg}, ${additionalX} , ${additionalY})" style="font-family: 'Mossport','Arial',sans-serif;font-weight: 400;line-height: 120%;fill: #ffffff; stroke: #ffffff; stroke-width: ${strokeWidth}; font-size: ${fs}px;font-variant: small-caps">${transformedText}</text>
                       <text x="${x}" y="${y}" ${additionalAttributes} transform="translate(0, 0) rotate(${deg}, ${additionalX} , ${additionalY})" style="font-family: 'Mossport','Arial',sans-serif;font-weight: 400;line-height: 120%;letter-spacing: .01em;fill: #ee2c3c; font-size: ${fs}px; font-variant: small-caps">${transformedText}</text>
 `;
    customImgBlockHidden.querySelector('svg').insertAdjacentHTML('beforeend', svgText);
  }

  const toDataUrl = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      callback(xhr.response);
    };
    xhr.open('GET', url, true);
    xhr.responseType = 'text';
    xhr.send();
  }

  const arrayOfImgData = dataImg;

  customImgRadio.forEach((radio) => {
    radio.addEventListener('change', () => {
      customImgBlock.innerHTML = '';
      customImgBlockHidden.innerHTML = '';
      const customImgBackBlockImg = document.querySelector('[data-custom-back-img] img');
      const fileInput = document.querySelector('[data-input-file]');

      if (customImgBackBlockImg) {
        customImgBackBlockImg.remove()
      }

      customImgBlock.innerHTML = '';
      const customImgElement = customImgTemplate(radio.dataset.customImgRadio);
      customImgBlock.insertAdjacentHTML('afterbegin', customImgElement);
      customImgBackBlock.insertAdjacentHTML('afterbegin', customImgElement);
      getImgWidthBlock.insertAdjacentHTML('afterbegin', customImgElement);

      toDataUrl(radio.dataset.customImgRadio,function(image){
        customImgBlockHidden.innerHTML = image;
        const coords = arrayOfImgData.filter((item) => item.id == radio.querySelector('input').value)[0];

        setTimeout(() => {
        customImgDataBlocks.forEach((block) => {

            fs = 100;
          const input = block.querySelector('input, select');

          let inputValue;
          if (input.options) {
            const selectedLi = document.querySelector(`[aria-selected="true"]`);
            if (selectedLi) {
              inputValue = selectedLi.textContent.replace( /[^0-9]/g, '');
            }
          } else {
            inputValue = input.value;
          }

          if (block.dataset.forCustomImg === 'name' ) {
            inputValue.split(' ').forEach((word, i) => {
              if (i === 0) {
                addTextToSvg(word, coords.firstName)
              } else {
                addTextToSvg(word, coords.secondName)
              }
            })
          } else if (block.dataset.forCustomImg === 'keys') {
              let splittingValue;
              if (inputValue.indexOf(';')!== -1) {
                splittingValue = ';';
              } else if (inputValue.indexOf(',')!== -1) {
                splittingValue = ',';
              } else {
                splittingValue = ' ';
              }
              inputValue.split(splittingValue).forEach((word, i) => {
                addTextToSvg(word.trim().replaceAll(',', ''), coords.keys[i])
            })
          } else if (input.options) {
            addTextToSvg(inputValue, coords.date)
          }
        })

          const getPng = async () => {
            const ctx = customImgCanvas.getContext('2d');
            const v = Canvg.fromString(ctx, customImgBlockHidden.innerHTML.toString());

            v.start();
            const dataURL = customImgCanvas.toDataURL();
            const blobBin = atob(dataURL.split(',')[1]);
            let array = [];
            for(let i = 0; i < blobBin.length; i++) {
              array.push(blobBin.charCodeAt(i));
            }
            const file=new Blob([new Uint8Array(array)], {type: 'image/png'});

        const dT = new DataTransfer();
            dT.items.add(new File([file], 'myNewFile.png', {
              type: "image/png",
        }));
        fileInput.files = dT.files;
          };

          getPng();

        }, 500);
      })
    })
  })
};

export {initCustomImg};
