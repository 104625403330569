window.yaShare = null;

const initYaShare = (share) => {
  window.yaShare = Ya.share2(share, {
    content: {
      url: window.location.href
    }
  });
}

const updateYaShare = () => {
  window.yaShare.updateContent({
    url: window.location.href
  });
}

const destroyYaShare = () => {
  if (window.yaShare !== null) {
  window.yaShare.destroy();
  window.yaShare = null;
}
}

export {initYaShare, updateYaShare, destroyYaShare}
