import {updateURL} from './url';

const slider = document.querySelector('.timeline__slides');
const FIRST_YEAR = 1920;

const checkProgress = (slidesLength, activeIndex, progressElement) => {
  const progress = ((activeIndex + 1) / slidesLength).toFixed(2);
  progressElement.style.transform = `scaleX(${progress})`;
};

const initTimelineSlider = (index) => {
  if (!slider) {
    return;
  }

  const progressElement = document.querySelector('[data-timeline-progress]');
  const pagination = document.querySelector('.timeline-controls__pagination');

  // eslint-disable-next-line
  const swiper = new Swiper(slider, {
    autoHeight: true,
    allowTouchMove: false,
    effect: 'fade',
    initialSlide:  index ? index : 0,
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: pagination,
      clickable: true,
      // eslint-disable-next-line
      renderBullet: function (index, className) {
        const year = FIRST_YEAR + index * 10;
        return '<span class="timeline-controls__bullet ' + className + '">' + year + '</span>';
      },
    },
    breakpoints: {
      768: {
        // autoHeight: false,
      },
    },
  });

  swiper.on('slideChange', function () {
    checkProgress(swiper.slides.length, swiper.activeIndex, progressElement);
    console.log(swiper.slides[swiper.activeIndex].dataset.slideId);
    updateURL(swiper.slides[swiper.activeIndex].dataset.slideId, 'years');
  });

  checkProgress(swiper.slides.length, swiper.activeIndex, progressElement);
};

export {initTimelineSlider};
