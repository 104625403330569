import {createFooterItemTemplate} from './templates/footer-template';
import {render} from '../../utils/render';

const footer = document.querySelector('.footer');
const menuList = document.querySelector('.footer__nav');

const renderMenuItems = (data) => {
  menuList.innerHTML = '';
  data.forEach((element) => {
    const menuItem = createFooterItemTemplate(element);
    render(menuList, menuItem);
  });
};

const renderFooter = (data) => {
  if (!data) {
    footer.innerHTML = '';
    return;
  }

  renderMenuItems(data);
};

export {renderFooter};
