import {inclineWord} from '../../utils/incline-word';
// import * as FilePond from '../../vendor/filepond';

const inputs = document.querySelectorAll('input[type="file"]');

let filesCount = 0;
let allFiles = [];

const MAX_WEIGHT = 10485760;
const ALL_MAX_WEIGHT = 52428800;
const FILE_TYPES = [
  '.jpeg',
  '.jpg',
  '.png',
  '.pdf',
  '.heic'
];

const validateFileType = (file, list) => {
  return (new RegExp('(' + list.join('|').replace(/\./g, '\\.') + ')$', 'i')).test(file.name);
};

// eslint-disable-next-line consistent-return
const returnFileSize = (number) => {
  if (number < 1024) {
    return number + 'bytes';
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + 'KB';
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + 'MB';
  }
};

const getAllFiles = (file, flag) => {
  if (flag === true) {
    allFiles.push(file);
  } else {
    allFiles.splice(flag, 1);
  }
};

const updateFormFiles = (formData) => {
  if (formData.has('user-file')) {
    formData.delete('user-file');
  }

  if (allFiles.length > 0) {
    formData.delete('file');
    allFiles.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });
  }
};

const createList = (header, list, preview) => {
  header.setAttribute('class', 'share-form__list-title');
  header.textContent = `Прикреплено ${inclineWord(filesCount, 'fileEndings')}`;
  preview.appendChild(header);

  list.setAttribute('class', 'share-form__preview-list');
  preview.appendChild(list);
};

const createItem = (listItem, file) => {
  listItem.setAttribute('class', 'share-form__preview-item');
  listItem.innerHTML = `
    <div class="share-form__preview-img-wrp">
      <img>
    </div>
    <div class="share-form__preview-title-wrp">
      <span class="share-form__preview-title">
      </span>
      <span class="share-form__preview-subtitle">
      </span>
    </div>
    <button type="button" class="btn share-form__preview-delete" aria-label="Удалить фотографию">
      <svg width="9" height="9" aria-hidden="true">
        <use xlink:href="./img/sprite_auto.svg#icon-delete"></use>
      </svg>
    </button>
  `;

  listItem.querySelector('.share-form__preview-title').textContent = `${file.name}`;
  listItem.querySelector('img').src = URL.createObjectURL(file);
};

const checkFileSize = (file, listItem) => {
  let actualSize = 0;

  allFiles.forEach((fileEl) => {
    actualSize += fileEl.size;
  });

  if (file.size > MAX_WEIGHT) {
    listItem.classList.add('is-invalid');
    listItem.querySelector('.share-form__preview-subtitle').textContent = 'Превышен вес файла';
  } else {
    if (actualSize > ALL_MAX_WEIGHT) {
      listItem.classList.add('is-invalid');
      listItem.querySelector('.share-form__preview-subtitle').textContent = 'Общий вес файлов не должен превышать 50мб';
    } else {
      listItem.querySelector('.share-form__preview-subtitle').textContent = `${returnFileSize(file.size)}`;
      getAllFiles(file, true);
    }
  }
};

const changeLabelText = () => {
  const inputTitle = document.querySelector('.custom-input--file .custom-input__label-text');

  if (filesCount === 0) {
    inputTitle.textContent = 'Прикрепить файлы';
  } else {
    inputTitle.textContent = 'Прикрепить ещё файлы';
  }
};

const inputChangeHandler = (evt) => {
  const preview = evt.target.closest('form').querySelector('.share-form__preview');
  const curFiles = evt.target.files;

  if (curFiles.length !== 0) {
    filesCount = filesCount + curFiles.length;
    const previewListTitle = preview.querySelector('.share-form__list-title');

    if (!previewListTitle) {
      const header = document.createElement('span');
      const list = document.createElement('ul');

      createList(header, list, preview);
    } else {
      previewListTitle.textContent = `Прикреплено ${inclineWord(filesCount, 'fileEndings')}`;
    }

    for (const file of curFiles) {
      if (validateFileType(file, FILE_TYPES)) {
        const listItem = document.createElement('li');

        createItem(listItem, file);

        checkFileSize(file, listItem);

        changeLabelText();

        preview.querySelector('.share-form__preview-list').appendChild(listItem);
        preview.style.maxHeight = preview.scrollHeight + 'px';
      }
    }

    setTimeout(() => {
      const items = preview.querySelectorAll('.share-form__preview-item');

      items.forEach((item) => {
        item.style.maxHeight = item.scrollHeight + 'px';
      });
    }, 200);
  }
};

const deleteHandler = (evt) => {
  if (!evt.target.closest('form')) {
    return;
  }

  const preview = evt.target.closest('form').querySelector('.share-form__preview');

  if (evt.target.closest('.share-form__preview-delete')) {
    const item = evt.target.closest('.share-form__preview-item');
    const list = evt.target.closest('.share-form__preview-list');
    const title = item.querySelector('.share-form__preview-title');
    const text = preview.querySelector('.share-form__list-title');

    item.style.maxHeight = '0';
    filesCount = filesCount - 1;

    const indexInList = [...list.querySelectorAll('.share-form__preview-item')].indexOf(item);

    getAllFiles(title.textContent, indexInList);

    if (filesCount === 0) {
      preview.style.maxHeight = null;

      setTimeout(() => {
        preview.removeChild(text);
        preview.removeChild(list);
      }, 200);
    } else {
      text.textContent = `Прикреплено ${inclineWord(filesCount, 'fileEndings')}`;
    }

    changeLabelText();

    setTimeout(() => {
      list.removeChild(item);
    }, 100);
  }
};

const updatePreview = () => {
  if (!inputs.length) {
    return;
  }

  inputs.forEach((input) => {
    input.addEventListener('change', inputChangeHandler);
  });

  window.addEventListener('click', deleteHandler);
};

export {updatePreview, updateFormFiles};
