import {createMenuItemTemplate} from './templates/menu-template';
import {render} from '../../utils/render';
import {playOnlyVideo} from '../play-video';

const menuList = document.querySelector('.main-nav__list');

const renderMenuItems = (data) => {
  menuList.innerHTML = '';
  const sportVideo = document.querySelector('[data-modal="video-sport"]');
  data.forEach((element) => {

    const menuItem = createMenuItemTemplate(element);
    render(menuList, menuItem);
    if (element.popupFields && sportVideo) {
      let title = element.popupFields.find((el) => el.key === 'popupTitle');
      let link = element.popupFields.find((el) => el.key === 'popupButtonLink');
      let btnText = element.popupFields.find((el) => el.key === 'popupButtonText');
      // let text = element.popupFields.find((el) => el.key === 'popupText');
      sportVideo.querySelector('.modal-video__title').innerHTML = title.value ? title.value : '';
      // sportVideo.querySelector('.modal-video__text').innerHTML = text.value ? text.value : '';

      if (link) {
        sportVideo.querySelector('.modal-video__btn').href = link.value ? link.value : 'https://day.moscow.sport/';
      } else {
        sportVideo.querySelector('.modal-video__btn').remove();
      }

      if (btnText) {
        sportVideo.querySelector('.modal-video__btn .btn__title').textContent = btnText.value ? btnText.value : 'НА САЙТ МЕРОПРИЯТИЯ';
      }
    }
  });
};

const renderMenu = (data) => {
  renderMenuItems(data);
  playOnlyVideo();

};

export {renderMenu};
