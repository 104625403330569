const createMenuItemTemplate = (data) => {
  let {name, path, active, popup, popupFields} = data;
  let video;
  if (popup) {
    video = popupFields.find((el) => el.key === 'popupVideo')
  }
  return `
  <li class="main-nav__item"><a class="main-nav__link ${active ? 'is-active' : ''}" href=${path} ${popup ? `data-open-modal="video-sport" data-modal-type="video" data-lazy-onload="${video.value}"` : ''}>${name}</a></li>`;
};

export {createMenuItemTemplate};
