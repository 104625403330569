const inputs = document.querySelectorAll('.custom-input input');
const textareas = document.querySelectorAll('.custom-textarea textarea');

const toggleInputPlaceholder = () => {
  if (!inputs.length) {
    return;
  }

  inputs.forEach((input) => {
    input.addEventListener('blur', () => {
      const label = input.nextElementSibling;

      if (input.closest('[type="tel"]') && input.value === '+7') {
        input.value = '';
      }

      if (label && label.closest('.custom-input__label')) {
        if (input.value) {
          label.style.opacity = 0;
        } else {
          label.style.opacity = null;
        }
      }
    });
  });
};

const toggleTextareaPlaceholder = () => {
  if (!textareas.length) {
    return;
  }

  textareas.forEach((textarea) => {
    textarea.addEventListener('blur', () => {
      const label = textarea.nextElementSibling;

      if (label && label.closest('.custom-textarea__label')) {
        if (textarea.value) {
          label.style.opacity = 0;
        } else {
          label.style.opacity = null;
        }
      }
    });
  });
};

const togglePlaceholder = () => {
  toggleInputPlaceholder();
  toggleTextareaPlaceholder();
};

export {togglePlaceholder};
