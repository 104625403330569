export const getData = (url, onGetSuccess, onGetError) => {
  fetch(url).then((res) => {
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.status);
  }).then((data) => {
    onGetSuccess(data);
  }).catch((err) => {
    if (onGetError) {
      onGetError(err);
    }
  });
};
