const updateURL = (params, type) => {
  if (!history.pushState || !params) {
    return;
  }
  const baseUrl = `${window.location.protocol}//${window.location.host + window.location.pathname}`;
  const newUrl = baseUrl + `?${type}=` + params;

  history.pushState(null, null, newUrl);
}

export {updateURL};
