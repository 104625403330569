import {isEmpty} from './data/timeline-controller';
import {modals} from './modals/init-modals';

const hoverItems = document.querySelectorAll('.open-book__hover-item');
const hoverTexts = document.querySelectorAll('.open-book__hover-text');
const openBookButton = document.querySelectorAll('.open-book__btn');
const layouts = document.querySelectorAll('.open-book__layout-bg');
const layoutBg = document.querySelector('.open-book__hover-bg');
const modalTimelineForAbout = document.querySelector('.modal-timeline');

const breakpoint = window.matchMedia('(max-width:1024px)');

let activeHoverItemId;
let tooltipData;

const removeActiveClass = (list, className) => list.forEach((item) => item.classList.remove(className));

const onMouseenterItem = ({target}) => {
  const hoverEl = target.closest('.open-book__hover-item');
  activeHoverItemId = hoverEl.dataset.hoverId;

  hoverItems.forEach((el) => {
    el.classList.remove('is-active');
    if (el.dataset.hoverId !== activeHoverItemId) {
      if (!el.classList.contains('is-hidden')) {
        el.classList.add('is-hidden');
      }
    }
  });

  hoverTexts.forEach((item) => {
    if (item.dataset.hoverId === activeHoverItemId) {
      item.classList.add('is-active');
    }
  });

  layouts.forEach((layout) => {
    if (layout.dataset.layoutId === activeHoverItemId) {
      layout.classList.add('is-active');
    }
  });

  openBookButton.forEach((item) => {
    item.style.zIndex = '0';
  });
};

const setActiveItem = (itemId) => {
  removeActiveClass(layouts, 'is-active');
  layoutBg.classList.add('is-show');
  openBookButton.forEach((item) => {
    item.style.opacity = '0.5';
  });

  hoverItems.forEach((el) => {
    el.classList.remove('is-active');
    el.classList.add('is-hidden');
    if (el.dataset.hoverId === itemId) {
      el.classList.remove('is-hidden');
    }
  });

  hoverTexts.forEach((item) => {
    item.classList.remove('is-active');
    if (item.dataset.hoverId === itemId) {
      item.classList.add('is-active');
    }
  });

  layouts.forEach((layout) => {
    if (layout.dataset.layoutId === itemId) {
      layout.classList.add('is-active');
    }
  });
};

const showTooltipModal = (data) => {
  modalTimelineForAbout.innerHTML = '';

  const story = `
    <h2 class="modal-timeline__title">${data.article.name}</h2>
    <div class="modal-timeline__slider">
      <div class="modal-timeline__slide">
                  <div class="modal-timeline__desc">
                    ${data.article.previewText}
                  </div>
                  <div class="modal-timeline__btns">
                    <a class="btn modal-timeline__tooltip-btn" href="timeline.html?years=${data.period.code}"><span class="btn__icon"><span class="btn__icon-wrp">
                      <svg width="45" height="44" aria-hidden="true">
                        <use xlink:href="img/sprite_auto.svg#icon-btn-arrow"></use>
                      </svg></span></span><span class="btn__title">узнать<br>больше</span>
                    </a>
                  </div>
                </div>
    </div>
  `;

  modalTimelineForAbout.insertAdjacentHTML('afterbegin', story);
  modals.open('timeline');
};

const renderTooltipModal = ({target}) => {
  const closestHoverItem = target.closest('[data-hover-id]');
  if (!closestHoverItem) {
    return;
  }
  const idOfTarget = closestHoverItem.dataset.hoverId;
  const storyData = tooltipData.find((el) => el.id == idOfTarget);
  showTooltipModal(storyData);
};

const onTouchItem = (e) => {
  const hoverEl = e.target.closest('.open-book__hover-item');
  if (!hoverEl) {

    removeActiveClass(hoverItems, 'is-hidden');
    removeActiveClass(hoverItems, 'no-pointer');
    removeActiveClass(hoverTexts, 'is-active');
    removeActiveClass(layouts, 'is-active');

    layoutBg.classList.remove('is-show');

    openBookButton.forEach((item) => {
      item.style = null;
    });


  } else {
    if (hoverEl) {
      activeHoverItemId = hoverEl.dataset.hoverId;
      setActiveItem(activeHoverItemId);
    }
  }
};

const deactivateItem = () => {
  activeHoverItemId = null;
  removeActiveClass(hoverItems, 'is-hidden');
  removeActiveClass(hoverTexts, 'is-active');
  removeActiveClass(layouts, 'is-active');

  openBookButton.forEach((item) => {
    item.style = null;
  });
};

const onMouseleaveItem = () => {
  deactivateItem();
};

const breakpointChecker = () => {
  hoverItems.forEach((item) => {
    if (!breakpoint.matches) {
      item.addEventListener('mouseenter', onMouseenterItem);
      item.addEventListener('mouseleave', onMouseleaveItem);
      item.addEventListener('click', renderTooltipModal);
    } else {
      item.removeEventListener('mouseenter', onMouseenterItem);
      item.removeEventListener('mouseleave', onMouseleaveItem);
      item.removeEventListener('click', renderTooltipModal);

    }
  });

  if (breakpoint.matches) {
    window.addEventListener('touchstart', renderTooltipModal);
  } else {
    window.removeEventListener('touchstart', renderTooltipModal);
  }
};

const initTooltips = () => {
  if (!hoverItems.length) {
    return;
  }

  breakpointChecker();
  breakpoint.addListener(breakpointChecker);
};

const renderTooltip = (data) => {
  if (!hoverItems.length) {
    return;
  }

  const originalData = data.pageContent.collage;
  const result = Object.keys(originalData).map((key) => originalData[key]);
  tooltipData = result;
};

export {initTooltips, renderTooltip};
