import {getData} from './api';
import {renderMenu} from './menu-controller';
import {renderFooter} from './footer-controller';
import {teamItem} from './templates/team-template';
import {filmIntroImage, filmItem, btnItem, filmsModalsHandler} from './templates/films-templates';
import {downloadLinkTemplate} from './templates/encyclopedia-template';
import {initTextSplitAnimation} from '../text-split-animation';
import {initHoverParallax} from '../init-hover-parallax';
import {render} from '../../utils/render';
import {renderTimeline} from './timeline-controller';
import {initAnimation} from '../init-animation';
import {playOnlyVideo} from '../play-video';
import {renderStarsStories} from './stars-stories-controller';
import {renderShareForm} from './share-form-controller';
import {renderTooltip} from '../init-tooltips';

export class Data {
  constructor() {
    this._parent = document.querySelector('[data-json]');
    this._loadHref = null;
    this._pageData = null;
    this._data = null;

    this._menuLinksList = document.querySelector('.main-nav__list');

    this._onGetDataSuccess = this._onGetDataSuccess.bind(this);
  }

  _renderFilmPage() {
    const docIntro = document.querySelector('.doc-intro');

    if (!docIntro) {
      return;
    }

    const pageTitle = document.querySelector('.doc-intro__header');
    const introImg = document.querySelector('.doc-intro__img');
    const introText = document.querySelector('.doc-intro__text');
    const docContentList = document.querySelector('.doc-content__list');
    const introContainer = docIntro.querySelector('.container');
    const docIntroBtn = docIntro.querySelector('.doc-intro__btn');

    this._pageData.page.btns.forEach((button) => {
      docIntroBtn.remove();
      const introBtn = btnItem(button);
      render(introContainer, introBtn);
    });

    this._pageData.page.textFields.forEach((textField) => {
      switch (textField.key) {
        case '1zagDocumentaries':
          pageTitle.innerHTML = textField.text;
          break;
        case '1podzagDocumentaries':
          introText.innerHTML = textField.text;
          break;
      }
    });

    if (introImg) {
      introImg.innerHTML = '';
      const introImageElement = filmIntroImage(this._pageData.page.previewPicture);
      render(introImg, introImageElement);
    }

    if (docContentList) {
      docContentList.innerHTML = '';
      this._pageData.pageContent.events.forEach((item, i) => {
        const filmItemEl = filmItem(item, i);
        render(docContentList, filmItemEl);
      });
      filmsModalsHandler();
    }
  }

  _renderTeamPage() {
    const team = document.querySelector('.team');
    if (!team) {
      return;
    }

    const pageTeamTitle = document.querySelector('.team__header');
    const teamList = document.querySelector('.team__list');

    pageTeamTitle.innerHTML = '';
    pageTeamTitle.innerHTML = this._pageData.page.name;

    teamList.innerHTML = '';
    this._pageData.pageContent.team.forEach((item) => {
      const teamItemEl = teamItem(item);
      render(teamList, teamItemEl);
    });

  }

  _renderShare() {
    const shareStory = document.querySelector('.share-form');

    if (!shareStory) {
      return;
    }

    const sessionInput = document.querySelector('input[name="sessid"]');
    const messageTitle = document.querySelector('.share-form__message-header');
    const messageText = document.querySelector('.share-form__message-text');
    const addFileWrapper = document.querySelector('.custom-input--file');
    const addFileLabel = addFileWrapper.querySelector('.custom-input__label-text');
    const subText = addFileWrapper.querySelector('.custom-input__label-subtext');
    const shareBtnTitle = document.querySelector('.share-form__btn .btn__title');
    const agreementText = document.querySelector('.share-form__agreement');
    const agreementTopText = document.querySelector('[data-agreement-top]');
    const agreementBottomText = document.querySelector('[data-agreement-bottom]');

    sessionInput.value = this._data.sessid;

    this._pageData.page.textFields.forEach((item) => {
      switch (item.key) {
        case 'addFilelabel':
          addFileLabel.innerHTML = item.text;
          break;
        case 'addFilesubText':
          subText.innerHTML = item.text;
          break;
        case 'sendBtnName':
          shareBtnTitle.innerHTML = item.text;
          break;
        case 'successMessageTitle':
          messageTitle.innerHTML = item.text;
          break;
        case 'successMessageText':
          messageText.innerHTML = item.text;
          break;
        case 'pdagreementShare':
          agreementText.innerHTML = item.text;
          break;
        case 'questionsShare':
          agreementTopText.innerHTML = item.text;
          break;
        case 'warningShare':
          agreementBottomText.innerHTML = item.text;
          break;
      }
    });
  }

  _renderAbout() {
    const mainIntro = document.querySelector('.intro');

    if (!mainIntro) {
      return;
    }

    const nextBtns = mainIntro.querySelectorAll('.intro__launch-btn');
    const introTitle = mainIntro.querySelector('.intro__title');
    const introDesc = mainIntro.querySelector('.intro__description');
    const blockquoteText = mainIntro.querySelector('.intro__blockquote-text');
    const blockquoteAuthor = mainIntro.querySelector('.intro__blockquote-author');
    const blockquotePosition = mainIntro.querySelector('.intro__blockquote-pos');

    nextBtns.forEach((btn, i) => {
      if (this._pageData.page.btns[i] && this._pageData.page.btns[i].name) {
        btn.querySelector('.btn__title').innerHTML = this._pageData.page.btns[i].name;
      }
    });

    this._pageData.page.textFields.forEach((textField) => {
      switch (textField.key) {
        case '1zagAbout':
          introTitle.innerHTML = textField.text;
          break;
        case '1podzagAbout':
          introDesc.innerHTML = textField.text;
          break;
        case 'quoteAbout':
          blockquoteText.innerHTML = textField.text;
          break;
        case 'nameAbout':
          blockquoteAuthor.innerHTML = textField.text;
          break;
        case 'jobAbout':
          blockquotePosition.innerHTML = textField.text;
          break;
      }
    });
  }

  _renderEnc() {
    const enc = document.querySelector('.intro-encyclopedia');

    if (!enc) {
      return;
    }

    const topTextBlock = document.querySelector('.intro-encyclopedia__text');
    const bottomTextBlock = document.querySelector('.intro-encyclopedia__bottom-text');
    const pageTitle = topTextBlock.querySelector('.intro-encyclopedia__title');
    const pageDesc = topTextBlock.querySelector('.intro-encyclopedia__description');
    const downloadLink = document.querySelector('.intro-encyclopedia__link');
    const pageContentTitle = bottomTextBlock.querySelector('.intro-encyclopedia__title');
    const pageContentDesc = bottomTextBlock.querySelector('.intro-encyclopedia__description');

    this._pageData.page.textFields.forEach((textField) => {
      switch (textField.key) {
        case '1zagBook':
          pageTitle.innerHTML = textField.text;
          break;
        case '1underzagBook':
          pageDesc.innerHTML = textField.text;
          break;
        case '2zagBook':
          pageContentTitle.innerHTML = textField.text;
          break;
        case '2underzagBook':
          pageContentDesc.innerHTML = textField.text;
          break;
      }
    });


    this._pageData.page.btns.forEach((button) => {
      downloadLink.remove();
      const downloadLinkElement = downloadLinkTemplate(button);
      render(bottomTextBlock, downloadLinkElement);
    });

  }

  _renderData() {
    const menu = this._pageData.menu;
    const footer = document.querySelector('.footer');

    if (menu) {
      renderMenu(this._pageData.menu);
    }

    renderTooltip(this._pageData);
    renderTimeline(this._pageData);
    renderStarsStories(this._pageData);
    renderShareForm(this._pageData);

    if (footer) {
      renderFooter(this._pageData.menu);
    }

    this._renderAbout();
    this._renderFilmPage();
    this._renderTeamPage();
    this._renderShare();
    this._renderEnc();

    initHoverParallax();
    initTextSplitAnimation();
    playOnlyVideo();


    if (sessionStorage.getItem('loader') === 'done') {
      initAnimation();
    }
  }

  _onGetDataSuccess(data) {
    this._data = data;
    this._pageData = data.data;

    this._renderData();
  }

  init() {
    if (!this._parent) {
      return;
    }

    this._loadHref = this._parent.dataset.url;
    getData(this._loadHref, this._onGetDataSuccess);
  }
}

