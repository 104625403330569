const textareas = document.querySelectorAll('.custom-textarea textarea');

const initTextareaFocus = () => {
  if (!textareas.length) {
    return;
  }

  textareas.forEach((textarea) => {
    textarea.addEventListener('focus', () => {
      textarea.closest('.custom-textarea').classList.add('is-active');
    });

    textarea.addEventListener('blur', () => {
      textarea.closest('.custom-textarea').classList.remove('is-active');
    });
  });
};

export {initTextareaFocus};
