const customSelectOptionTemplate = (obj) => {
  const {name, id} = obj;

  return `
  <li class="custom-select__item" tabindex="0" data-select-value="${id}" aria-selected="false" role="option">
    ${name}
    <svg width="11" height="9" aria-hidden="true">
        <use xlink:href="img/sprite_auto.svg#icon-select-check"></use>
    </svg>
  </li>
  `
}

export {customSelectOptionTemplate};
