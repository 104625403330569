import {updateURL} from './url';
import {destroyYaShare, initYaShare, updateYaShare} from './initYaShare';

const initModalTimelineSlider = (firstSlide) => {
  const slider = document.querySelector('.modal-timeline__slider');

  if (!slider) {
    return;
  }

  const timelineModal = document.querySelector('.modal-timeline');

  const nextBtn = slider.querySelector('.modal-timeline__control--next');
  const prevBtn = slider.querySelector('.modal-timeline__control--prev');

  const swiper = new Swiper(slider, {
    allowTouchMove: false,
    autoHeight: true,
    loop: true,
    initialSlide: firstSlide,
    navigation: {
      nextEl: nextBtn,
      prevEl: prevBtn,
    },
  });

  let slideDataset = swiper.slides[swiper.activeIndex].dataset;

  const shareBlock = timelineModal.querySelector('.swiper-slide-active .ya-share2');

  swiper.on('slideChange', function (obj) {
    if (timelineModal.getBoundingClientRect().top < 0) {
      timelineModal.scrollIntoView({behavior: 'smooth'});
    }

    const activeSlide = obj.slides[obj.activeIndex];

    slideDataset = activeSlide.dataset;

    updateURL(slideDataset.articleId ? slideDataset.articleId : slideDataset.storyId, activeSlide.dataset.type);
    if (activeSlide.querySelector('.ya-share2')) {
    destroyYaShare();
      initYaShare(activeSlide.querySelector('.ya-share2'));
    }
  });

  updateURL(slideDataset.articleId ? slideDataset.articleId : slideDataset.storyId, swiper.slides[swiper.activeIndex].dataset.type);
  if (shareBlock) {
    initYaShare(shareBlock);
  }
};

export {initModalTimelineSlider};
