const showSuccessMessage = (form) => {
  // const shareFormWrp = form.closest('.share-form__wrp');
  const shareForm = form.closest('.share-form');
  const message = document.querySelector('.share-form__message');

  // shareFormWrp.classList.add('is-hidden');
  message.classList.add('show');

  if (shareForm.classList.contains('error')) {
    shareForm.classList.remove('error');
  }
};

const showErrorMessage = (form) => {
  const shareForm = form.closest('.share-form');
  shareForm.classList.add('error');
};

export {showSuccessMessage, showErrorMessage};
