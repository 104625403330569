import {initLottieAnimation} from './lottie-animation';
import {launchStartSequence} from './init-sequence';
import {initAnimation} from './init-animation';

class Loader {
  constructor() {
    this._body = document.body;
    this._loaderElement = document.querySelector('[data-loader]');
    this._percentElement = document.querySelector('.test-loader__percent');
    this._logoElement = document.querySelector('.test-loader__logo');
    this._loaderProgressLine = document.querySelector('.loader__progress');
    this._intro = document.querySelector('.intro');

    this._duration = this._loaderElement && this._loaderElement.dataset.duration ? +this._loaderElement.dataset.duration : 5000;
    this._stopPoint = this._loaderElement && this._loaderElement.dataset.stop ? +this._loaderElement.dataset.stop : 100;
    this._finalTimeout = this._loaderElement && this._loaderElement.dataset.finalTimeout ? +this._loaderElement.dataset.finalTimeout : 0;
    this._step = this._duration / this._stopPoint;
    this._minimalStep = 30;
    this._percent = 0;
    this._innerPercent = 0;
    this._animationDelay = 600;
    this._timer = null;

    this._breakpointSm = window.matchMedia('(max-width:767px)');
  }

  _onWindowLoad() {
    if (!this._loaderElement) {
      return;
    }

    window.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => {
        this._step = this._minimalStep;
        this._animationTimer();
      });
    });
  }

  _fillLine() {
    this._loaderProgressLine.style.width = `${this._percent}%`;
  }

  _animationTimer() {
    clearInterval(this._timer);
    this._timer = setInterval(() => {
      this._percent = this._percent + 1;

      if (this._percent > 100) {
        clearInterval(this._timer);
        this._finishLoader();
        return;
      }
      this._fillLine();
    }, this._step);
  }

  _launchSequence() {
    launchStartSequence();
  }

  _startLoader() {
    this._body.classList.add('scroll-lock');
    this._loaderElement.classList.add('is-active');
    initLottieAnimation();
    this._animationTimer();
  }

  _finishLoader() {
    setTimeout(() => {
      this._loaderElement.classList.add('is-hidden');
    }, this._finalTimeout);

    setTimeout(() => {
      this._body.classList.remove('scroll-lock');
      this._loaderElement.classList.remove('is-active');
      this._loaderElement.classList.remove('is-hidden');
      initAnimation();

      if (this._intro) {
        this._launchSequence();
      }

      sessionStorage.setItem('loader', 'done');
    }, this._finalTimeout + this._animationDelay);
  }

  init() {
    if (!this._loaderElement) {
      return;
    }

    if (sessionStorage.getItem('loader') !== 'done' || this._loaderElement.dataset.alwaysPlay === '') {
      this._onWindowLoad();
      this._startLoader();
    } else {
      this._loaderElement.remove();
      initAnimation();
    }
  }
}

export {Loader};
