
import {inclineWord} from '../../utils/incline-word';
import {initModalTimelineSlider} from '../init-modal-timeline-slider';
import {initHistorySlider} from '../init-history-slider';
import {initTimelineSlider} from '../init-timeline-slider';
import {modals} from '../modals/init-modals';
import {updateURL} from '../url';
import {playOnlyVideo} from '../play-video';
import {initYaShare} from '../initYaShare';

const modalsBtn = document.querySelectorAll('[data-open-modal="timeline"]');
const timeline = document.querySelector('.timeline');
const timelineSlides = document.querySelectorAll('.timeline-slide');
const modalTimeline = document.querySelector('.modal-timeline');

let timelineData = null;
let activeYearData = null;

let typeOfStory = null;
let idOfStory = null;
let isOpen = true;

const renderTimelineSlides = () => {
  timelineSlides.forEach((slide) => {
    const historyBtn = slide.querySelector('.history-btn');

    const slideCode = slide.dataset.slideId;
    const activeSlideData = [...timelineData.pageContent.timeline].find((el) => el.code === slideCode);

    if (!activeSlideData.stories) {
      if (historyBtn) {
        historyBtn.classList.add('visually-hidden');
      }
      return;
    }

    const title = slide.querySelector('.timeline-slide__title');
    const text = slide.querySelector('.timeline-slide__text');
    if (title && text) {
    title.innerHTML = '';
    text.innerHTML = '';
    title.innerHTML = activeSlideData.name;
    text.innerHTML = activeSlideData.detailText;
    }

    const authorsPhoto = activeSlideData.stories.filter((el) => {
      return !isEmpty(el.detailPicture);
    });

    // render history button
    // historyBtn.dataset.slideId = slideCode;
    historyBtn.innerHTML = `
        ${authorsPhoto.length ? `
        <div class="history-btn__images">
          ${authorsPhoto.slice(0, 3).map((item) => `
          <img src="${item.detailPicture.path}.${item.detailPicture.format}" srcset="${item.detailPicture.path}@2x.${item.detailPicture.format} 2x" width="31" height="31" alt="${item.detailPicture.alt}">`).join('')}
        </div>` : ''}
        <div class="history-btn__desc">
          <p class="history-btn__text">Читать</p>
          <p class="history-btn__quantity">${inclineWord(activeSlideData.stories.length, 'historyEndings')}</p>
        </div>
        <svg class="history-btn__svg" width="28" height="28" aria-hidden="true">
          <use xlink:href="img/sprite_auto.svg#icon-circle-i"></use>
        </svg>`;
    // render buttons
    if (activeSlideData.links.length) {
      let shareBtn;
      let videoBtn;
      activeSlideData.links.forEach((btn) => {
        if (btn.ufDesc === 'share') {
          shareBtn = `
            <a class="btn timeline-slide__button" href="${btn.ufLink}"><span class="btn__icon"><span class="btn__icon-wrp">
              <svg width="45" height="44" aria-hidden="true">
                <use xlink:href="img/sprite_auto.svg#icon-btn-arrow"></use>
              </svg></span></span><span class="btn__title">${btn.ufTitle}</span>
            </a>
          `;
        }
        if (btn.ufDesc === 'video') {
          videoBtn = `
            <button class="btn timeline-slide__button timeline-slide__button--video" type="button" data-video-title="${btn.ufVideoTitle}" data-video-desc="${btn.ufDesc}" ${btn.ufLink === '' ? 'data-open-modal="video-mock"' : `data-open-modal="video" data-modal-type="video" data-lazy-onload=${btn.ufLink}`}><span class="btn__icon"><span class="btn__icon-wrp">
              <svg width="12" height="17" aria-hidden="true">
                <use xlink:href="img/sprite_auto.svg#icon-play"></use>
              </svg></span></span><span class="btn__title">${btn.ufTitle}</span>
            </button>
          `;
        }
      });

      const buttonsWrapper = slide.querySelector('.timeline-slide__buttons');
      buttonsWrapper.innerHTML = `
          ${shareBtn ? `${shareBtn}` : ''}
          ${videoBtn ? `${videoBtn}` : ''}
        `;

      if (window.location.href.indexOf('?') < 0) {
      initTimelineSlider();
    }
    }
  });
};

const isEmpty = (obj) => {
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      return false;
    }
  }
  return true;
};

const removeSliderElements = () => {
  const timelineSlider = document.querySelector('.modal-timeline__slider');
  const timelineSlides1 = document.querySelectorAll('.modal-timeline__slide');
  const title = document.querySelector('.modal-timeline__title');
  const timelineForm = document.querySelector('.modal-timeline .timeline-form');
  const timelineControls = document.querySelector('.modal-timeline__controls');
  if (timelineSlider) {
    timelineSlider.remove();
  }
  if (title) {
    title.remove();
  }
  if (timelineForm) {
    timelineForm.remove();
  }
  if (timelineControls) {
    timelineControls.remove();
  }
  if (timelineSlides1.length) {
    timelineSlides1.forEach((el) => {
      el.remove();
    });
  }
};

const renderHistoryModal = () => {
  removeSliderElements();
  const sliderElement = `
    <h2 class="modal-timeline__title">ваши истории</h2>
    <div class="swiper modal-timeline__slider">
      <div class="swiper-wrapper">
        ${activeYearData.stories.map((item) => `
                <div class="modal-timeline__slide swiper-slide" data-type="story" ${item.id ? `data-story-id=${item.id}` : ''}>
                  <div class="modal-timeline__author">
                  ${!isEmpty(item.detailPicture) ? `<img src="${item.detailPicture.path}.${item.detailPicture.format}" srcset="${item.detailPicture.path}@2x.${item.detailPicture.format} 2x" width="24" height="24" alt="${item.detailPicture.alt}">` : ''}
                  <span class="modal-timeline__author-name">${item.name}</span>
                  ${item.radio ? ` <p class="modal-timeline__radio">
                    История рассказана на
                    <img src="${item.radio.ufFile.path}.${item.radio.ufFile.format}" width="67" height="42" alt="${item.radio.ufFile.alt}">
                  </p>` : ''}
                  </div>
                  ${item.titles ? ` <p class="modal-timeline__insignia">
                    ${item.titles}
                  </p>` : ''}
                  <div class="modal-timeline__desc">
                    ${item.story}
                  </div>
                  <div class="share">
                    <p class="share__text">Понравилась история? Поделитесь ей в социальных сетях!</p>
                    <div class="ya-share2" data-curtain data-shape="round" data-color-scheme="whiteblack" data-copy="extraItem" data-services="vkontakte,odnoklassniki"></div>
                  </div>
                  ${item.file.length > 1 ? `
                  <div class="modal-timeline__history-slider history-slider">
                    <div class="history-slider__main swiper">
                      <div class="swiper-wrapper">
                        ${item.file.map((img) => `
                        <div class="history-slider__slide swiper-slide"><img src="${img.path}.${img.format}" srcset="${img.path}@2x.${img.format} 2x" width="616" height="438" alt="${img.alt}">
                        </div>
                        `).join('')}
                      </div>
                      <button class="history-slider__control history-slider__control--next" type="button">
                        <svg width="24" height="24" aria-hidden="true">
                          <use xlink:href="img/sprite_auto.svg#icon-arrow-right"></use>
                        </svg>
                      </button>
                      <button class="history-slider__control history-slider__control--prev" type="button">
                        <svg width="24" height="24" aria-hidden="true">
                          <use xlink:href="img/sprite_auto.svg#icon-arrow-right"></use>
                        </svg>
                      </button>
                    </div>
                    <div class="history-slider__thumbs swiper">
                      <div class="swiper-wrapper">
                        ${item.file.map((img) => `
                        <div class="history-slider__thumb-slide swiper-slide"><img src="${img.path}.${img.format}" srcset="${img.path}@2x.${img.format} 2x" width="616" height="438" alt="${img.alt}">
                        </div>
                        `).join('')}
                      </div>
                    </div>
                  </div>
                  ` : ''}
                  ${item.file.length === 1 ? `
                  <div class="modal-timeline__history-img">
                    <img src="${item.file[0].path}.${item.file[0].format}" srcset="${item.file[0].path}@2x.${item.file[0].format} 2x" width="616" height="438" alt="${item.file.alt}">
                  </div>
                  ` : ''}
                </div>
          `).join('')}
      </div>
      ${activeYearData.stories.length > 1 ? `
        <div class="modal-timeline__controls">
          <button class="modal-timeline__control modal-timeline__control--prev" type="button"><span>ПРЕДЫДУЩАЯ история</span>
            <svg width="16" height="16" aria-hidden="true">
              <use xlink:href="img/sprite_auto.svg#icon-arrow"></use>
            </svg>
          </button>
          <button class="modal-timeline__control modal-timeline__control--next" type="button"><span>СЛЕДУюЩАЯ история</span>
            <svg width="16" height="16" aria-hidden="true">
              <use xlink:href="img/sprite_auto.svg#icon-arrow"></use>
            </svg>
          </button>
        </div>` : ''}
    </div>
  `;

  let activeSlideIndex = idOfStory ? activeYearData.stories.findIndex((el) => el.id == idOfStory) : 0;

  modalTimeline.insertAdjacentHTML('afterbegin', sliderElement);

  // modalTimeline.querySelectorAll('.ya-share2').forEach((share) => {
  //   initYaShare(share);
  // });

  if (activeYearData.stories.length > 1) {
    initModalTimelineSlider(activeSlideIndex);
  } else {
    updateURL(activeYearData.stories[0].id, 'story');
    initYaShare(modalTimeline.querySelector('.ya-share2'))
  }
  initHistorySlider();
};

const renderArticleModal = (articleId) => {
  removeSliderElements();
  let activeIndex = 0;

  activeYearData.articles.forEach((item, i) => {
    if (item.code === articleId) {
      activeIndex = i;
    }
  });

  const sliderElement = `
  <div class="swiper modal-timeline__slider">
    <div class="swiper-wrapper">
      ${activeYearData.articles.map((item) => `
      <div class="modal-timeline__slide swiper-slide" data-type="article" ${item.code ? `data-article-id=${item.code}` : ''}>
        <h2 class="modal-timeline__title">${item.name}</h2>
        <div class="modal-timeline__desc">
        ${item.previewText}
        </div>
      <div class="share">
        <p class="share__text">Понравилась история? Поделитесь ей в социальных сетях!</p>
        <div class="ya-share2" data-curtain data-shape="round" data-color-scheme="whiteblack" data-copy="extraItem" data-services="vkontakte,odnoklassniki"></div>
      </div>
      </div>`).join('')}
    </div>
    <div class="modal-timeline__controls">
      <button class="modal-timeline__control modal-timeline__control--prev" type="button"><span>ПРЕДЫДУЩАЯ статья</span>
        <svg width="16" height="16" aria-hidden="true">
          <use xlink:href="img/sprite_auto.svg#icon-arrow"></use>
        </svg>
      </button>
      <button class="modal-timeline__control modal-timeline__control--next" type="button"><span>СЛЕДУюЩАЯ статья</span>
        <svg width="16" height="16" aria-hidden="true">
          <use xlink:href="img/sprite_auto.svg#icon-arrow"></use>
        </svg>
      </button>
    </div>
  </div>`;

  modalTimeline.insertAdjacentHTML('afterbegin', sliderElement);
  initModalTimelineSlider(activeIndex);
  // modalTimeline.querySelectorAll('.ya-share2').forEach((share) => {
  //   initYaShare(share);
  // });

  let timer = setTimeout(function check() {
    if (isOpen && modals) {
      modals.open('timeline');
      isOpen = false;
    } else {
      timer = setTimeout(check, 300);
    }
  }, 300);
};

const renderVideoMockModal = (desc, title) => {
  const videoModal = document.querySelector('[data-modal="video-mock"]');

  if (videoModal) {
    videoModal.querySelector('.modal-video__title').innerHTML = title;
    // videoModal.querySelector('.modal-video__text').innerHTML = desc;
  }
};

const renderTimelineModals = ({target}) => {
  const activeSlide = target.closest('.timeline-slide');
  const slideCode = activeSlide.dataset.slideId;
  activeYearData = [...timelineData.pageContent.timeline].find((el) => el.code === slideCode);
  if (target.closest('.history-btn')) {
    renderHistoryModal();
  }

  if (target.closest('.timeline-slide__tooltip') || target.closest('.timeline-slide__hover-item')) {
    const articleId = target.closest('.timeline-slide__tooltip') ? target.closest('.timeline-slide__tooltip').dataset.articleId : target.closest('.timeline-slide__hover-item').dataset.articleId;
    renderArticleModal(articleId);
  }

  if (target.closest('[data-open-modal="video-mock"]')) {
    let desc = target.closest('[data-open-modal="video-mock"]').dataset.videoDesc;
    let title = target.closest('[data-open-modal="video-mock"]').dataset.videoTitle;
    renderVideoMockModal(desc, title);
  }
};

const timelineModalsHandler = () => {
  const videoBtns = document.querySelectorAll('[data-open-modal="video-mock"]');

  if (modalsBtn.length) {
    modalsBtn.forEach((btn) => btn.addEventListener('click', renderTimelineModals));
  }

  if (videoBtns.length) {
    videoBtns.forEach((btn) => btn.addEventListener('click', renderTimelineModals));
  }
};

const onPageLoad = () => {
  const url = window.location.href;
  if (url.indexOf('?') < 0) {
    return;
  }

  typeOfStory = url.substring(url.indexOf('?') + 1, url.indexOf('='));
  idOfStory = url.substring(url.indexOf('=') + 1).replace('#', '');
  if (typeOfStory === 'article') {
    document.querySelector(`.timeline-slide__tooltip[data-article-id='${idOfStory}']`).click();
    const slide = document.querySelector(`.timeline-slide__tooltip[data-article-id='${idOfStory}']`).closest('.timeline-slide');
    if (slide) {
      setTimeout(() => {
        initTimelineSlider([...document.querySelectorAll('.timeline__item')].indexOf(slide));
      }, 300);
    }
  }
  else if (typeOfStory === 'story') {
    const year = [...timelineData.pageContent.timeline].find((obj) => [...obj.stories].find((el) => el.id == idOfStory));
    const slide = document.querySelector(`[data-slide-id='${year.code}']`);
    if (slide) {
      setTimeout(() => {
        initTimelineSlider([...document.querySelectorAll('.timeline__item')].indexOf(slide));
        slide.querySelector('.history-btn').click();
      }, 300);
    }
  } else if (typeOfStory === 'years') {
    const slide = document.querySelector(`[data-slide-id='${idOfStory}']`);
    if (slide) {
      setTimeout(() => {
        initTimelineSlider([...document.querySelectorAll('.timeline__item')].indexOf(slide));
      }, 300);
    }
  }
};

const renderTimeline = (data) => {
  if (!timeline) {
    return;
  }

  timelineData = data;
  renderTimelineSlides();
  timelineModalsHandler();
  playOnlyVideo();
  onPageLoad();
};

export {renderTimeline, removeSliderElements, isEmpty, onPageLoad, renderVideoMockModal};
