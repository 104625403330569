import FormsValidate from './form-validate';
import { showSuccessMessage, showErrorMessage} from './show-success-message';
import {updateFormFiles} from './add-file-preview';
const formWrappers = document.querySelectorAll('[data-validate]');

const resetForm = (form) => {
  setTimeout(() => {
    window.clearForm(form);
  }, 1000);
};

const baseValidationSuccessCallback = (e) => {
  e.preventDefault();
  // В данном колбеке бэкендер будет писать запрос на отправку формы на сервер и обрабатывать возможные ошибки при отправке
  resetForm(e.target);
};

const baseValidationErrorCallback = (e) => {
  e.preventDefault();
};

const customExampleValidationSuccessCallback = (e) => {
  e.preventDefault();
  // В данном колбеке бэкендер будет писать запрос на отправку формы на сервер и обрабатывать возможные ошибки при отправке
  resetForm(e.target);
  // eslint-disable-next-line no-console
  console.log('Ваша форма успешна отправлена');
};

const customExampleValidationErrorCallback = (e) => {
  e.preventDefault();
  // eslint-disable-next-line no-console
  console.error('Отправка формы невозможна, заполните все обязательные поля');
};

const onShareFormSuccessCallback = (evt) => {
  evt.preventDefault();
  if (ym) {
    ym(90744917, 'reachGoal', 'moderation_button_click');
  }

  const form = evt.target;
  const shareStory = form.closest('.share-story');
  const formData = new FormData(form);
  const URL = form.getAttribute('action');
  const method = form.getAttribute('method');
  const fileInput = document.querySelector('[data-input-file]');

  formData.append('userfile', fileInput.files[0]);
  // showSuccessMessage(form);

  $.ajax({
    type: method,
    url: URL,
    data: formData,
    success: function () {
      console.log('success');
      // showSuccessMessage(form);
      modals.open('modal-form-success');
      shareStory.classList.add('is-send');
      resetForm(form);
      if (ym) {
        ym(90744917, 'reachGoal', 'final-screen-share');
      }
    },
    error: function (err) {
      console.log('error');
      console.log(err);
      showErrorMessage(form);
    },
    cache: false,
    contentType: false,
    processData: false,
  });
};

const onTimelineFormSuccessCallback = (evt) => {
  evt.preventDefault();
  const form = evt.target;
  const shareStory = form.closest('.timeline-form');
  const formData = new FormData(form);
  const URL = form.getAttribute('action');
  const method = form.getAttribute('method');

  updateFormFiles(formData);

  $.ajax({
    type: method,
    url: URL,
    data: formData,
    dataType: 'json',
    success: function () {
      shareStory.classList.add('is-send');
      resetForm(form);
    },
    error: function () {
      console.log('error');
    },
    cache: false,
    contentType: false,
    processData: false,
  });
};

const onShareFormErrorCallback = (evt) => {
  evt.preventDefault();
};

const callbacks = {
  base: {
    // Колбек при успешной валидации формы при попытке её отправки
    validationSuccessCallback: baseValidationSuccessCallback,
    // Колбек при не успешной валидации формы при попытке её отправки, не связан с запросами на сервер
    validationErrorCallback: baseValidationErrorCallback,
  },
  customExample: {
    validationSuccessCallback: customExampleValidationSuccessCallback,
    validationErrorCallback: customExampleValidationErrorCallback,
  },
  shareFormSubmit: {
    validationSuccessCallback: onShareFormSuccessCallback,
    validationErrorCallback: onShareFormErrorCallback,
  },
  timelineFormSubmit: {
    validationSuccessCallback: onTimelineFormSuccessCallback,
    validationErrorCallback: onShareFormErrorCallback,
  },
};

const setCustomPhoneInputsEvent = () => {
  if (document.querySelectorAll('[data-validate-type="phone"] input').length) {
    document.querySelector('html').addEventListener('input', ({target}) => {
      if (target.closest('[data-validate-type="phone"]')) {
        target.closest('[data-validate-type="phone"]').querySelector('input').dispatchEvent(new Event('input'));
      }
    });
  }
};

const initFormValidate = () => {
  if (formWrappers.length) {
    setCustomPhoneInputsEvent();
    formWrappers.forEach((wrapper) => {
      let callback = wrapper.dataset.callback;

      if (!callback) {
        callback = 'base';
      }

      const formValidate = new FormsValidate(wrapper, callbacks[callback]);
      return formValidate.init();
    });
  }
};

export {initFormValidate};
