const hoverItems = document.querySelectorAll('[data-hover-timeline]');
const breakpoint = window.matchMedia('(max-width:767px)');
const tooltips = document.querySelectorAll('.timeline-slide__tooltip');

const getActiveText = (parentSvg, hoverItem) => {
  const hoverTexts = parentSvg.querySelectorAll('.timeline-slide__hover-text');
  const hoverItemId = hoverItem.dataset.itemId;
  const activeText = [...hoverTexts].find((item) => item.dataset.itemId === hoverItemId);
  return activeText;
};

const onMouseenterItem = (e) => {
  if (e.pointerType === 'touch') {
    return;
  }

  const parentSlide = e.target.closest('.timeline-slide');
  const el = parentSlide.querySelector('.timeline-slide__hover-svg');
  const hoverItem = e.target.closest('[data-item-id]');
  const activeText = getActiveText(el, hoverItem);
  activeText.classList.add('is-active');

  // tooltips.forEach((tooltip) => {
  //   tooltip.style.zIndex = '-1';
  // });
};

const onMouseleaveItem = (e) => {
  if (e.pointerType === 'touch') {
    return;
  }

  const parentSlide = e.target.closest('.timeline-slide');
  const el = parentSlide.querySelector('.timeline-slide__hover-svg');
  const hoverItem = e.target.closest('[data-item-id]');
  const activeText = getActiveText(el, hoverItem);
  activeText.classList.remove('is-active');

  // tooltips.forEach((tooltip) => {
  //   tooltip.style = null;
  // });
};

const initHoverTimeline = () => {
  if (!hoverItems.length) {
    return;
  }

  hoverItems.forEach((item) => {
    if (!breakpoint.matches) {
      item.addEventListener('pointerenter', onMouseenterItem);
      item.addEventListener('pointerleave', onMouseleaveItem);
    } else {
      item.removeEventListener('pointerenter', onMouseenterItem);
      item.removeEventListener('pointerleave', onMouseleaveItem);
    }
  });
};

export {initHoverTimeline};
