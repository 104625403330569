const initAnchorLinks = () => {
  const links = document.querySelectorAll('[data-anchor-link]');

  if (!links.length) {
    return;
  }

  const header = document.querySelector('[data-header]');

  const moveTo = new window.MoveTo({
    tolerance: header ? header.offsetHeight : 0,
  });


  links.forEach(function (trigger) {
    moveTo.registerTrigger(trigger);
  });
};

export {initAnchorLinks};
