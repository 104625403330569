import {validateInputs} from '../form/form-validate';
import {initCustomImg} from '../init-custom-img';
import {initCustomSelect} from '../form/init-custom-select';
import {customSelectOptionTemplate} from './templates/custom-select-option-template';
import {initShareStorySlider} from '../init-share-story-slider';

const shareFormParts = [...document.querySelectorAll('[data-form-part]')];
const shareFormNext = document.querySelector('[data-form-next]');
const shareFormSubmit = document.querySelector('[data-form-submit]');
const shareForm = document.querySelector('[data-callback="shareFormSubmit"]');
const shareSelect = document.querySelector('.custom-select__list');
const shareFormForm = document.querySelector('[data-callback="shareFormSubmit"] form');
const shareFormImgSelector = document.querySelector('[data-custom-img-selector]');
const shareFormFlags = document.querySelectorAll('[data-form-part-flag]');
const shareFormAttention = document.querySelector('.share-form__attention');
const shareFormBack = document.querySelector('[data-share-form-back]');
let topPos = 0;
if (shareFormParts.length) {
  topPos = shareFormParts[0].getBoundingClientRect().top;
}
let activePart;
let activeFormPartInputs;
let activePartIndex;
let nextPart;

const renderImgSelector = (data) => {
  shareFormImgSelector.innerHTML = '';

  const perChunk = 12; // radioBtns per slide

  const radioArray = data.sports;

  const splittedArrays = radioArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  shareFormImgSelector.innerHTML = '';

  splittedArrays.forEach((arr) => {
    const swiperSlide = `
      <div class="swiper-slide">
          ${arr.map((el) =>
      `
              <div class="custom-radio" data-custom-img-radio="${el.ufSvgPreform.path}.${el.ufSvgPreform.format}" data-validate-type="radio">
                <label>
                  <input class="visually-hidden" type="radio" name="data[sport]" value="${el.id}">
                  <img src="${el.ufIcon.path}.${el.ufIcon.format}" alt="${el.ufIcon.alt}" height="65">
                  <span class="custom-radio__label">${el.ufName}</span>
                </label>
              </div>
            `).join('')}
      </div>
    `;
    shareFormImgSelector.insertAdjacentHTML('beforeend', swiperSlide);
  });

  initCustomImg();
  initShareStorySlider();
};

const renderSelect = (data) => {
  shareSelect.innerHTML = '';

  document.querySelector('.share-form__select').classList.remove('is-initialized');

  document.querySelector('[name="data[time]"]').remove();

  data.period.forEach((el) => {
    const optionItem = customSelectOptionTemplate(el);
    shareSelect.insertAdjacentHTML('afterbegin', optionItem);
  });

  initCustomSelect();
};

const initWrp = () => {
  activePart = shareFormParts.find((el) => el.classList.contains('is-active'));
  activeFormPartInputs = activePart.querySelectorAll('[data-validate-type]');
  activePartIndex = shareFormParts.indexOf(activePart);
  nextPart = shareFormParts[activePartIndex + 1];
  activeFormPartInputs.forEach((el) => {
    el.addEventListener('input', () => {
      const input = el.querySelector('input, select, textarea');
      validateInputs(el.dataset.validateType, input);
      if (activeFormPartInputs.length === activePart.querySelectorAll('[data-validate-type].is-valid').length) {
        shareFormNext.classList.remove('is-disabled');
        shareFormAttention.classList.add('visually-hidden');
      } else {
        shareFormNext.classList.add('is-disabled');
        shareFormAttention.classList.remove('visually-hidden');
      }
    });

    el.addEventListener('change', () => {
      const input = el.querySelector('input');
      if (input && input.type === 'radio') {
        const inputs = el.querySelectorAll('input');
        inputs.forEach((radio) => {
          if (radio.checked) {
            shareFormNext.classList.remove('is-disabled');
            shareFormAttention.classList.add('visually-hidden');
          }
        });
      }
    });
  });
};

if (shareFormBack) {
  shareFormBack.addEventListener('click', () => {
    if (activePartIndex - 1 === 0) {
      shareFormBack.classList.add('is-hidden');
    }
    if (shareFormParts[activePartIndex - 1]) {
      activePart.classList.remove('is-active');
      shareFormParts[activePartIndex - 1].classList.add('is-active');
      shareFormFlags[activePartIndex].classList.remove('is-active');
      shareFormFlags[activePartIndex - 1].classList.add('is-active');
      activePart = shareFormParts[activePartIndex - 1];
      activePartIndex = shareFormParts.indexOf(activePart);
      nextPart = shareFormParts[activePartIndex + 1];
      shareFormNext.classList.remove('is-disabled');
      shareForm.classList.remove('is-result');
      shareFormNext.classList.remove('is-hidden');
      shareFormSubmit.classList.add('is-hidden');

      initWrp();
    }
  });
}

const initShareForm = () => {
  if (shareFormParts.length === 0 || !shareFormNext) {
    return;
  }

  initWrp();
  shareFormNext.addEventListener('click', () => {
    if (shareFormParts[0] === activePart && ym) {
      ym(90744917, 'reachGoal', 'first_button_click');
    }
    // shareFormNext.classList.add('is-disabled');
    if (nextPart) {
      shareFormBack.classList.remove('is-hidden');
      activePart.classList.remove('is-active');
      nextPart.classList.add('is-active');
      shareFormFlags[activePartIndex].classList.remove('is-active');
      shareFormFlags[activePartIndex + 1].classList.add('is-active');
      initWrp();
      window.scrollTo({top: topPos, behavior: 'smooth'});
    }
    if (shareFormParts[shareFormParts.length - 1] === activePart) {
      const formData = new FormData(shareFormForm);
      let formDataObj = {};
      formData.forEach((value, key) => (formDataObj[key] = value));
      document.querySelector('.share-form__result-name').innerHTML = formDataObj["data[name]"];
      document.querySelector('.share-form__result-title').innerHTML = formDataObj["data[topic]"];
      document.querySelector('.share-form__result-story').innerHTML = formDataObj["data[story]"];
      shareFormNext.classList.add('is-hidden');
      shareFormSubmit.classList.remove('is-hidden');
      shareForm.classList.add('is-result');
      if (ym) {
        ym(90744917, 'reachGoal', 'second_button_click');
      }
    }
  });
};

const renderShareForm = (data) => {
  if (!shareForm) {
    return;
  }
  renderSelect(data.pageContent);
  renderImgSelector(data.pageContent);
  initShareForm();
};

export {renderShareForm};
