const initLottieAnimation = () => {
  const player = document.querySelector('.lottie');

  if (!player) {
    return;
  }

  const animationPath = player.dataset.animationPath;
  player.load(animationPath);
};

export {initLottieAnimation};
