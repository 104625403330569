const teamItem = ({name, previewText, previewPicture}) => {
  return (`
    <li class="team__item" data-animate>
      <div class="team__img-wrp fade-in" data-parallax>
        <div class="team__img">
          <picture>
            <source type="image/webp" srcset="${previewPicture.path}.webp, ${previewPicture.path}@2x.webp 2x"><img src="${previewPicture.path}.${previewPicture.format}" srcset="${previewPicture.path}@2x.${previewPicture.format} 2x" width="426" height="458" alt="${previewPicture.alt}">
          </picture>
        </div>
        <div class="team__item-figure team__item-figure--1" data-parallax-item data-speed="2">
          <svg class="team__item-icon up-scale team__item-icon--1" width="231" height="242" aria-hidden="true" style="--figure-index: 0;">
            <use xlink:href="img/sprite_auto.svg#team-1-bg-1"></use>
          </svg>
          <svg class="team__item-icon up-scale team__item-icon--2" width="115" height="115" aria-hidden="true" style="--figure-index: 1;">
            <use xlink:href="img/sprite_auto.svg#team-1-bg-2"></use>
          </svg>
        </div>
      </div><span class="team__item-title d-400 fit-15">${name}</span><span class="team__item-text d-600 fit-15">${previewText}</span>
    </li>
  `);
};

export {teamItem};
