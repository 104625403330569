
let videoContent;

const playVideo = (btns) => {
  btns.forEach((btn) => {
    btn.addEventListener('click', () => {
      insertVideo(btn);
    });
  });

  window.addEventListener('click', (evt) => {
    if (evt.target.closest('[data-close-modal]') && videoContent !== undefined) {
      delSrc(videoContent);
    }
  });

  document.addEventListener('keydown', (evt) => {
    const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

    if (isEscKey && videoContent !== undefined) {
      evt.preventDefault();
      delSrc(videoContent);
    }
  });
};

const openWithVideo = (btn) => {
  insertVideo(btn);

  window.addEventListener('click', (evt) => {
    if (evt.target.closest('[data-close-modal]') && videoContent !== undefined) {
      delSrc(videoContent);
    }
  });

  document.addEventListener('keydown', (evt) => {
    const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

    if (isEscKey && videoContent !== undefined) {
      evt.preventDefault();
      delSrc(videoContent);
    }
  });
}

const insertVideo = (btn) => {
  const videoParent = document.querySelector(`[data-modal=${btn.dataset.openModal}]`);
  videoContent = videoParent.querySelector(`iframe`);
  const innerIframeHtml = document.querySelector(`[data-modal=${btn.dataset.openModal}] .modal-video__iframe`);
  if (videoContent) {
    const isVkVideo = btn.dataset.lazyOnload.toString().includes('vk.com/video_ext')
    if (btn.hasAttribute('data-lazy-onload') && btn.dataset.lazyOnload !== '' && (btn.dataset.lazyOnload.toString().includes('embed') || isVkVideo)) {
      videoContent.src = btn.dataset.lazyOnload + `${isVkVideo ? '&' : '?'}autoplay=1`;
    } else if (btn.dataset.lazyOnload.toString().includes('jpg') && innerIframeHtml) {
      innerIframeHtml.innerHTML = `
          <img src="${btn.dataset.lazyOnload}" alt="Фото" width="680" height="350">
            `
    } else if (innerIframeHtml) {
      innerIframeHtml.innerHTML = `
          <img src="https://ss.sport-express.ru/userfiles/materials/160/1600218/volga.jpg" alt="Фото" width="680" height="350">
          `
    }
  }
};

const delSrc = (video) => {
  setTimeout(() => {
    if (video) {
      video.src = '';
    }
  }, 200);
};

const playOnlyVideo = () => {
  const playVideoButtons = document.querySelectorAll('[data-modal-type="video"]');
  if (!playVideoButtons.length) {
    return;
  }

  playVideo(playVideoButtons)
};

export {playVideo, playOnlyVideo, openWithVideo};
