const createFooterItemTemplate = (data) => {
  const {name, path, active, popup, popupFields} = data;
  let video;
  if (popup) {
    video = popupFields.find((el) => el.key === 'popupVideo')
  }
  return `
    <li class="footer__nav-item"><a class="footer__nav-link ${active ? 'is-active' : ''}" href=${path} ${popup ? `data-open-modal="video-sport" data-modal-type="video" data-lazy-onload="${video.value}"` : ''}>${name}</a></li>
  `;
};

const createFooterBtnTemplate = ({name, href}) => {
  return `
    <a class="btn footer__btn" href="${href}"><span class="btn__icon"><span class="btn__icon-wrp">
          <svg width="45" height="44" aria-hidden="true">
            <use xlink:href="img/sprite_auto.svg#icon-btn-arrow"></use>
          </svg></span></span><span class="btn__title">${name}</span>
    </a>
  `;
};

export {createFooterItemTemplate, createFooterBtnTemplate};
