/* data-animate-height="n", где n = [0, 100]
    определяет в какой части экрана будет появляться элемент
    n = 0 -- блок достиг верхней части экрана
    n = 50 -- по дефолту. центр экрана
    n = 100 -- блок достиг нижней части экрана*/

let elTop;
let windowHeight;

const returnAnimatePoint = (el) => {
  elTop = el.getBoundingClientRect().top;
  windowHeight = window.innerHeight;
  const offsetHeight = el.dataset.animateHeight
    ? el.dataset.animateHeight
    : windowHeight / 1.4 - elTop;
  return offsetHeight;
};

const initAnimation = () => {
  const screens = [...document.querySelectorAll('[data-animate]')];
  if (screens.length) {
    const trackingScreenBlock = () => {
      screens.forEach((screen) => {
        if (returnAnimatePoint(screen) > 0 && !screen.classList.contains('show')) {
          screen.classList.add('show');
        }
      });
    };
    trackingScreenBlock();
    window.addEventListener('scroll', trackingScreenBlock);
    window.addEventListener('orientationchange', trackingScreenBlock);
  }
};

export {initAnimation};
