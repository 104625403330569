
const introText = document.querySelector('.intro__text-wrapper');
const nextBtns = document.querySelectorAll('.intro__launch-btn');
const introBg1 = document.querySelector('.intro__sequence-bg--1');
const introBg2 = document.querySelector('.intro__sequence-bg--2');

const showIntroText = () => {
  introText.classList.add('show');
  introText.classList.remove('is-hidden');
  nextBtns.forEach((btn) => {
    btn.classList.add('is-show');
    btn.classList.add('no-scaling');
  });

  introBg1.classList.remove('show');
  introBg2.classList.add('show');

  setTimeout(() => {
    nextBtns.forEach((btn) => {
      btn.classList.remove('no-scaling');
    });
  }, 1000);
};

const showBook = () => {
  introText.classList.add('hide-animate');
  introText.classList.remove('show');

  setTimeout(() => {
    nextBtns.forEach((btn) => {
      btn.classList.add('hide-animate');
      btn.classList.remove('is-next-show');
    });
  }, 0);

  setTimeout(() => {
    introText.classList.remove('hide-animate');
  }, 800);

  setTimeout(() => {
    nextBtns.forEach((btn) => {
      btn.classList.remov('hide-animate');
      btn.classList.remove('is-next-show');
    });
  }, 1000);
};

export {showIntroText, showBook};
