const downloadLinkTemplate = ({name, description}) => {
  description = description=== '' ? 'pdf/100_let_moscow_sport.pdf' : description;
  return `
    <a class="btn intro-encyclopedia__link" href="${description}" download><span class="btn__icon"><span class="btn__icon-wrp">
        <svg width="45" height="44" aria-hidden="true">
          <use xlink:href="img/sprite_auto.svg#icon-btn-arrow"></use>
        </svg></span></span><span class="btn__title">${name}</span>
    </a>
  `;
};

export {downloadLinkTemplate};
