import {initModalTimelineSlider} from '../init-modal-timeline-slider';
import {updateURL} from '../url';
import {initHistorySlider} from '../init-history-slider';
import {isEmpty, removeSliderElements} from './timeline-controller';
import {modals} from '../modals/init-modals';
import {initTimelineSlider} from '../init-timeline-slider';
import {initAnchorLinks} from '../initi-anchor-links';
import {initModalStarsStoriesSlider} from '../init-modal-stars-stories-slider';

let dataArray = null;
let typeOfStory = null;
let idOfStory = null;
let isOpen = true;

const modalTimeline = document.querySelector('.modal-timeline');
const container = document.querySelector('.stars-stories__container');
const navigation = document.querySelector('.stories-navigation__list');

const renderStories = (data) => {

  dataArray = Object.keys(data.pageContent.timeline).map((key) => data.pageContent.timeline[key]);

  dataArray.forEach((el) => {
    const {id, name, stories, code} = el;

    const storyTitleElement = `
     <div class="stars-stories__block" data-story-id="${id}" id="story-${id}">
                <h2 class="stars-stories__sub-title">${name}</h2>
                <ul class="stars-stories__list">
                ${stories.map((item) => `
                   <li class="stars-stories__story" data-single-story-id="${item.id}">
                     <h3 class="stars-stories__name">${item.name}</h3>
                     <p class="stars-stories__dignity">${item.achievement}</p>
                     <a class="stars-stories__caption" href="#" data-open-modal="timeline">${item.topic}</a>
                   </li>
                `).join('')}
                </ul>
              </div>
    `;

    container.insertAdjacentHTML('beforeend', storyTitleElement);
    if (navigation) {
      const navigationElement = `
              <li class="stories-navigation__item">
                <a class="stories-navigation__link" href="#story-${id}" data-anchor-link="">
                  <span class="stories-navigation__year">${code}</span>
                  <sup>-е</sup>
                </a>
              </li>
    `;
      navigation.insertAdjacentHTML('beforeend', navigationElement);
}
  });
};

const renderButtons = (data) => {
  const btnContainer = document.querySelector('.stars-stories__buttons');
  let btnDataArray = Object.keys(data.page.btns).map((key) => data.page.btns[key]);

  btnContainer.innerHTML = '';

  btnDataArray.forEach((el, i) => {
    const {name, description} = el;

    const btnElement = `
               <a class="btn stars-stories__btn ${i === 0 ?  'stars-stories__btn--blue' : ''}" href="${description}.html"><span class="btn__icon"><span class="btn__icon-wrp">
                    <svg width="77" height="77" aria-hidden="true">
                      <use xlink:href="img/sprite_auto.svg#icon-btn-arrow"></use>
                    </svg></span></span><span class="btn__title">${name}</span>
              </a>
    `;

    btnContainer.insertAdjacentHTML('beforeend', btnElement);
  });
};

const renderHistoryModal = (target) => {
  removeSliderElements();

  let stories = null;
  let activeSlideIndex = null;

  if (target instanceof HTMLElement)  {
    stories = dataArray.find((el) => el.id == target.closest('[data-story-id]').dataset.storyId);
    activeSlideIndex = stories.stories.findIndex((el) => el.id == target.closest('[data-single-story-id]').dataset.singleStoryId);
  } else {
    stories = dataArray.find((el) => el.stories.find((obj) => obj.id == target));
    const stroiesStory = stories.stories.findIndex((el) => el.id == target);
    activeSlideIndex = stroiesStory > -1 ? stroiesStory : stroiesStory;
  }

  const sliderElement = `
    <h2 class="modal-timeline__title">истории звезд</h2>
    <div class="swiper modal-timeline__slider">
      <div class="swiper-wrapper">
        ${stories.stories.map((item) => `
                <div class="modal-timeline__slide swiper-slide" data-type="story" ${item.id ? `data-story-id=${item.id}` : ''}>
            <div class="modal-timeline__author ${!isEmpty(item.achievements) ? 'modal-timeline__author--mb' : ''}">
                  ${!isEmpty(item.detailPicture) ? `<img src="${item.detailPicture.path}.${item.detailPicture.format}" srcset="${item.detailPicture.path}@2x.${item.detailPicture.format} 2x" width="24" height="24" alt="${item.detailPicture.alt}">` : ''}
                  <span class="modal-timeline__author-name">${item.name}</span>
                  </div>
            ${!isEmpty(item.achievements) ? `<p class="modal-timeline__author-achievements" >${item.achievements}</p>` : ''}
                  <div class="modal-timeline__desc">
                    ${item.story}
                  </div>
            ${item.morePhoto.length ? `
              <div class="modal-timeline__inner-slider-wrap" data-modal-slider="parent">
                <div class="modal-timeline__inner-slider">
                  <div class="modal-timeline-slider swiper" data-modal-slider="main">
                    <div class="modal-timeline-slider__wrapper swiper-wrapper">
                    ${item.morePhoto.map((slide) => `
                      <div class="modal-timeline-slider__slide swiper-slide">
                        <img src="${slide.path}.${slide.format}" alt="${slide.alt}"/>
                      </div>
                    `).join('')}
                    </div>
                  </div>
                    <button type="button" class="modal-timeline-slider__button modal-timeline-slider__button--prev" aria-label="следующий слайд">
                      <svg width="40" height="40" aria-hidden="true">
                        <use xlink:href="img/sprite_auto.svg#icon-slider-prev"></use>
                      </svg>
                    </button>
                    <button type="button" class="modal-timeline-slider__button modal-timeline-slider__button--next" aria-label="следующий слайд">
                      <svg width="40" height="40" aria-hidden="true">
                        <use xlink:href="img/sprite_auto.svg#icon-slider-next"></use>
                      </svg>
                    </button>
                </div>
                <div class="modal-timeline__thumbs">
                  <div thumbsSlider="" class="modal-timeline-thumbs swiper" data-modal-slider="thumbs">
                    <div class="modal-timeline-thumbs__wrapper swiper-wrapper">
                      ${item.morePhoto.map((slide) => `
                        <div class="modal-timeline-thumbs__slide swiper-slide">
                          <img src="${slide.path}.${slide.format}" alt="${slide.alt}"/>
                        </div>
                      `).join('')}
                    </div>
                  </div>
                </div>
            </div>` : ''}
                </div>
          `).join('')}
      </div>
      ${stories.stories.length > 1 ? `
        <div class="modal-timeline__controls">
          <button class="modal-timeline__control modal-timeline__control--prev" type="button"><span>ПРЕДЫДУЩАЯ история</span>
            <svg width="16" height="16" aria-hidden="true">
              <use xlink:href="img/sprite_auto.svg#icon-arrow"></use>
            </svg>
          </button>
          <button class="modal-timeline__control modal-timeline__control--next" type="button"><span>СЛЕДУюЩАЯ история</span>
            <svg width="16" height="16" aria-hidden="true">
              <use xlink:href="img/sprite_auto.svg#icon-arrow"></use>
            </svg>
          </button>
        </div>` : ''}
      <div class="timeline-form">
        <div class="timeline-form__inner">
          <h2 class="timeline-form__title">ВОЙДИте В ИСТОРИЮ МОСКОВСКОГО СПОРТА</h2>
          <p class="timeline-form__desc">Если у&nbsp;вас или ваших родственников есть история и&nbsp;фотоматериалы, связанные с&nbsp;историческими спортивными событиями и&nbsp;легендарными спортсменами Москвы или личная спортивная история&nbsp;&mdash; у&nbsp;вас есть возможность оставить ее&nbsp;на&nbsp;нашем сайте.</p>
          <div class="timeline-form__banner">
            <svg width="98" height="71" aria-hidden="true">
              <use xlink:href="img/sprite_auto.svg#icon-prize"></use>
            </svg>
            <p class="timeline-form__banner-text">Лучшие истории получат приз от&nbsp;Департамента спорта города Москвы</p>
          </div>
          <div class="timeline-form__form is-initialized" data-validate="" data-callback="timelineFormSubmit">
            <a class="btn timeline-form__btn" href="share-story.html">
              <span class="btn__icon">
                <span class="btn__icon-wrp">
                  <svg width="45" height="44" aria-hidden="true">
                    <use xlink:href="img/sprite_auto.svg#icon-btn-arrow"></use>
                  </svg>
                </span>
                </span>
                  <span class="btn__title">НАПИСАТЬ СВОЮ<br>ИСТОРИЮ</span>
                <span class="btn__subtext">Для публикации на&nbsp;сайте</span>
            </a>
          </div>
        </div>
        <p class="timeline-form__prompt">Авторский отдел оставляет за собой принятие решения о публикации историй.</p>
      </div>
    </div>
  `;

  modalTimeline.insertAdjacentHTML('afterbegin', sliderElement);

  if (stories.stories.length > 1) {
    initModalTimelineSlider(activeSlideIndex);
    updateURL(stories.stories[activeSlideIndex].id, 'story');
  } else {
    updateURL(stories.stories[0].id, 'story');
  }
  initHistorySlider();
  initModalStarsStoriesSlider();

  isOpen = false;
};

const starsStoriesModalsHandler = () => {
  const modalsBtn = document.querySelectorAll('[data-open-modal="timeline"]');

  if (modalsBtn.length) {
    modalsBtn.forEach((btn) => btn.addEventListener('click', (e) => {
      e.preventDefault();
      renderHistoryModal(e.target);
    }));
  }
};

const onPageLoad = () => {
  const url = window.location.href;
  if (url.indexOf('?') < 0) {
    return;
  }

  idOfStory = window.location.href.substring(url.indexOf('=') + 1).replace('#', '');

  if (isOpen) {
    renderHistoryModal(idOfStory);
    modals.open('timeline');
  }
};

const renderStarsStories = (data) => {
  if (!container) {
    return;
  }

  renderStories(data);
  renderButtons(data);
  starsStoriesModalsHandler();
  onPageLoad();
  initAnchorLinks();
};

const socialFloat = document.querySelectorAll('.stars-stories__bg');
const footer = document.querySelector('.footer');

if (socialFloat.length) {
  let float0Top = window.getComputedStyle(socialFloat[0], null).getPropertyValue('top').slice(0, -2)*1;
  let float1Top = window.getComputedStyle(socialFloat[1], null).getPropertyValue('top').slice(0, -2)*1;

  document.addEventListener('scroll', function () {
    socialFloat.forEach((float, i) => {
      let top = i === 0 ? float0Top : float1Top;

      const getRectTop = (el) => {
        let rect = el.getBoundingClientRect();
        return rect.top;
      };

      if (getRectTop(footer) < window.innerHeight) {
        float.style.top = top + (getRectTop(footer) - window.innerHeight) + 'px';
      } else {
        float.style.top = null;
      }
    });
  });
}

export {renderStarsStories};
