import {inclineWord} from '../../utils/incline-word';

const textareas = document.querySelectorAll('.custom-textarea textarea');
let max;
let length;
let text;

const checkInputLength = () => {
  if (!textareas.length) {
    return;
  }

  textareas.forEach((textarea) => {
    const customTextarea = textarea.closest('.custom-textarea');
    const message = customTextarea.querySelector('.custom-textarea__message');

    if (!message) {
      return;
    }

    text = message.textContent;
    max = textarea.getAttribute('maxlength');
    message.textContent = text + ' ' + max + ' символов';

    textarea.addEventListener('input', () => {
      length = max - textarea.value.length;
      message.textContent = text + ' ' + inclineWord(length, 'symbolEndings');
    });
  });
};

export {checkInputLength};
