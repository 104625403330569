export const renderPosition = {
  BEFOREBEGIN: 'beforebegin',
  AFTERBEGIN: 'afterbegin',
  BEFOREEND: 'beforeend',
  AFTEREND: 'afterend',
};

export const render = (container, template, pos = renderPosition.BEFOREEND) => {
  container.insertAdjacentHTML(pos, template);
  return container.lastElementChild;
};
