const breakpointSM = window.matchMedia('(max-width:767px)');
const THRESHOLD = -10;

const onMouseMoveItem = (evt) => {
  const itemBlock = evt.target.closest('[data-parallax]');
  const item = itemBlock.querySelector('[data-parallax-item]');

  if (!item) {
    return;
  }

  const {clientX, clientY} = evt;
  const {offsetWidth, offsetHeight} = item;
  const {left, top} = item.getBoundingClientRect();

  const horizontalOffset = (clientX - left) / offsetWidth - 0.5;
  const verticalOffset = (clientY - top) / offsetHeight - 0.5;

  const speed = item.getAttribute('data-speed');

  item.style.transform = `translate3D(${(horizontalOffset * speed * THRESHOLD)}px, ${(verticalOffset * speed * THRESHOLD)}px, 0)`;
};

const clearParallaxState = (block) => {
  const item = block.querySelector('[data-parallax-item]');
  item.style.transform = 'translate3D(0, 0, 0)';
};

const initHoverParallax = () => {
  const parallaxBlocks = document.querySelectorAll('[data-parallax]');
  if (!parallaxBlocks.length) {
    return;
  }

  const breakpointChecker = () => {
    if (breakpointSM.matches) {
      parallaxBlocks.forEach((block) => {
        clearParallaxState(block);
        block.removeEventListener('mousemove', onMouseMoveItem);
      });
    } else {
      parallaxBlocks.forEach((block) => {
        block.addEventListener('mousemove', onMouseMoveItem);
      });
    }
  };

  breakpointSM.addListener(breakpointChecker);
  breakpointChecker();
};

export {initHoverParallax};
