const initShareStorySlider = () => {
  const slider = document.querySelector('.share-aside .swiper');

  if (!slider) {
    return;
  }

  const nextBtn = document.querySelector('.share-form__wrp--img .swiper-button-next');
  const prevBtn = document.querySelector('.share-form__wrp--img .swiper-button-prev');

  const swiper = new Swiper(slider, {
    slidesPerView: 'auto',
    freeMode: true,
    threshold: 5,
    breakpoints: {
      768: {
        slidesPerView: 1,
        navigation: {
          nextEl: nextBtn,
          prevEl: prevBtn,
        },
      }
    }
  });

}

export {initShareStorySlider};
